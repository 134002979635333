import {
  dtdMultiPickupDropoff,
  DTD_SAVED_INVOICE_FEE_TYPE,
  DTD_TRANSLATED_FEE_TYPE,
} from '~/Pages/Payment/Components/AdditionalFeeFormInvoice/constants';

export const ANTD_UPLOAD_ACCEPTED_IMAGE_TYPE = '.png, .jpg, .jpeg, image/*';

export const CURRENCY_CODES = {
  IDR: 'IDR',
  MYR: 'MYR',
  SGD: 'SGD',
  USD: 'USD',
};

export const COUNTRIES = {
  ID: 'ID',
  MY: 'MY',
};

export const SHIPMENT_STATUSES = {
  UPCOMING: 'UPCOMING',
  PLANNED: 'PLANNED',
  QUEUED: 'QUEUED',
  QUEUED_AND_BTMS_PLANNED: 'QUEUED_AND_BTMS_PLANNED',
  ASSIGNED: 'ASSIGNED',
  ASSIGNING: 'ASSIGNING',
  ONGOING_TO_PICKUP: 'ONGOING_TO_PICKUP',
  ARRIVE_AT_PICKUP: 'ARRIVE_AT_PICKUP',
  START_LOADING: 'START_LOADING',
  FINISH_LOADING: 'FINISH_LOADING',
  ONGOING_TO_DESTINATION: 'ONGOING_TO_DESTINATION',
  ON_SHIPMENT: 'ON_SHIPMENT',
  ARRIVE_AT_DESTINATION: 'ARRIVE_AT_DESTINATION',
  START_UNLOADING: 'START_UNLOADING',
  FINISH_UNLOADING: 'FINISH_UNLOADING',
  DOCUMENT_UPLOADED: 'DOCUMENT_UPLOADED',
  SHIPMENT_COMPLETE: 'SHIPMENT_COMPLETE',
  CANCELLED_BY_SHIPPER: 'CANCELLED_BY_SHIPPER',
  CANCELLED_BY_TRANSPORTER: 'CANCELLED_BY_TRANSPORTER',
  CANCELLED: 'CANCELLED',
  UNFULFILLED: 'UNFULFILLED',
  REASSIGNED_BY_SHIPPER: 'REASSIGNED_BY_SHIPPER',
  REJECTED_BY_TRANSPORTER: 'REJECTED_BY_TRANSPORTER',
  CONFIRMATION_EXPIRED: 'CONFIRMATION_EXPIRED',
  WAITING_FOR_CONFIRMATION: 'WAITING_FOR_CONFIRMATION',
  WAITING_FOR_SHIPPER_CONFIRMATION: 'WAITING_FOR_SHIPPER_CONFIRMATION',
  JOB_BIDDING: 'JOB_BIDDING',
  JOB_UNASSIGNED: 'JOB_UNASSIGNED',
  LATE_TO_ORIGIN: 'LATE_TO_ORIGIN',
  LATE_TO_DESTINATION: 'LATE_TO_DESTINATION',
  JOB_TRANSPORTER_CONFIRMED: 'JOB_TRANSPORTER_CONFIRMED',
  WAITING_FOR_JOB_CONFIRMATION: 'WAITING_FOR_JOB_CONFIRMATION',
  BIDDING: 'BIDDING',
  REASSIGNED_BY_BROKERAGE: 'REASSIGNED_BY_BROKERAGE',
  CANCELLED_BY_INTERNAL: 'CANCELLED_BY_INTERNAL',
  UNASSIGNED: 'UNASSIGNED',
  POL_UPLOADED: 'POL_UPLOADED',
  JOB_WAITING_FOR_SHIPPER_CONFIRMATION: 'JOB_WAITING_FOR_SHIPPER_CONFIRMATION',
};

export const LOCAL_SHIPMENT_STATUSES = {
  ...(window.DO_DISPATCH_AND_ALLOCATION_STMS_TTMS
    ? {
      UPCOMING: 'upcoming',
    }
    : {}),
  ALL: 'all',
  PLANNED: 'planned',
  AT_WAREHOUSE: 'at-warehouse',
  IN_TRANSIT: 'in-transit',
  AT_LOCATION: 'at-location',
  ONGOING: 'ongoing',
  COMPLETED: 'completed',
};

export const FIRST_MILE_SHIPMENT_TABS = {
  DOOR_TO_DOOR_PURCHASE_ORDER: 'door-to-door',
  CONTAINER: 'container',
  TRUCKING: 'trucking',
  SHIPPING_LINE: 'shipping-line',
  DOORING_AGENT: 'dooring-agent',
};

export const QUEUE_STATE = {
  ASSIGNED: 'ASSIGNED',
  SWITCHED: 'SWITCHED',
  QUEUED: 'QUEUED',
  ALREADY_ASSIGNED: 'ALREADY_ASSIGNED',
  TRANSPORTER_REALLOCATED_ASSIGNED: 'TRANSPORTER_REALLOCATED_ASSIGNED',
  TRANSPORTER_REALLOCATED_QUEUED: 'TRANSPORTER_REALLOCATED_QUEUED',
  TRANSPORTER_REALLOCATED_SWITCHED: 'TRANSPORTER_REALLOCATED_SWITCHED',
  TRANSPORTER_CANNOT_BE_REALLOCATED: 'TRANSPORTER_CANNOT_BE_REALLOCATED',
};

export const ETA_STATE = {
  BETWEEN: 'SHIPMENT_OVERLAPPED',
  NORMAL: 'NORMAL',
  OVERLAPPED: 'ETA_OVERLAPPED',
};

export const DRIVER_ACTIVE_STATUS = {
  UPCOMING: 'UPCOMING',
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
};

export const PHONE_NUMBER_STATUS = {
  REGISTERED: 'REGISTERED',
  NOT_REGISTERED: 'NOT_REGISTERED',
  FIRST_TIME_SIGN_IN: 'FIRST_TIME_SIGN_IN',
};

export const SHIPPER_PLATFORM_WHITELIST = {
  ALLOW_LOGIN: 'ALLOW_LOGIN',
  ALLOW_FIRST_TIME_LOGIN: 'ALLOW_FIRST_TIME_LOGIN',
  NOT_ALLOWED_TO_LOGIN: 'NOT_ALLOWED_TO_LOGIN',
};

export const COMPANY_TYPES = {
  PT: 'PT',
  UD: 'UD',
  CV: 'CV',
  FA: 'FA',
};

export const DO_REJECTION_REASONS = {
  VEHICLE_NOT_READY: 'VEHICLE_NOT_READY',
  VEHICLE_MAINTENANCE_NEEDED: 'VEHICLE_MAINTENANCE_NEEDED',
  DRIVER_NOT_READY: 'DRIVER_NOT_READY',
  OTHER_REASON: 'OTHER_REASON',
};

export const SHIPMENT_LIST_ACTION = {
  SELECT_SHIPMENT: 'SELECT_SHIPMENT',
  CREATE_INVOICE: 'CREATE_INVOICE',
};

export const DO_MANUAL_ALLOCATION_REASONS = {
  CONTRACT_PRICE_NOT_AVAILABLE: 'CONTRACT_PRICE_NOT_AVAILABLE',
  CONTRACT_PRICE_HAS_CHANGED: 'CONTRACT_PRICE_HAS_CHANGED',
  NEW_PRICE_ROUTE: 'NEW_PRICE_ROUTE',
  MARKETPLACE_TIME_LIMIT: 'MARKETPLACE_TIME_LIMIT',
  OTHER_REASON: 'OTHER_REASON',
};

export const DO_CANCELLATION_REASONS = {
  TRUCK_NO_SHOW: 'TRUCK_NO_SHOW',
  COMPLIANCE_ISSUE: 'COMPLIANCE_ISSUE',
  FULL_STOCK: 'FULL_STOCK',
  NO_EMPTY_JUGS: 'NO_EMPTY_JUGS',
  NO_PALLET_JUGRACK: 'NO_PALLET_JUGRACK',
  NO_STOCK: 'NO_STOCK',
  LOADING_DELAY: 'LOADING_DELAY',
  TKBM_ISSUE: 'TKBM_ISSUE',
  FORCE_MAJEURE: 'FORCE_MAJEURE',
  ALLOCATION_REVISION: 'ALLOCATION_REVISION',
  OTHER_REASON: 'OTHER_REASON',
};

export const DO_CANCELLATION_REASONS_V2_CATEGORY = {
  CHANGE_POINT: 'CHANGE_POINT',
  DO_ALLOCATION: 'DO_ALLOCATION',
  INTERNAL_ISSUE: 'INTERNAL_ISSUE',
  AUDIT_BY_AM: 'AUDIT_BY_AM',
  SHIPMENT_RESCHEDULE: 'SHIPMENT_RESCHEDULE',
  SHIPPER_ISSUE: 'SHIPPER_ISSUE',
  TECHNICAL_ISSUE: 'TECHNICAL_ISSUE',
  TRANSPORTER_ISSUE: 'TRANSPORTER_ISSUE',
  WRONG_INPUT_BY_CS: 'WRONG_INPUT_BY_CS',
  OTHER_REASON: 'OTHER_REASON',
};

export const DO_CANCELLATION_REASONS_V2 = {
  [DO_CANCELLATION_REASONS_V2_CATEGORY.CHANGE_POINT]: {
    DESTINATION_AFTER_TRUCK_ARRIVAL_FOR_LOADING: 'DESTINATION_AFTER_TRUCK_ARRIVAL_FOR_LOADING',
    DESTINATION_BEFORE_TRUCK_ARRIVAL: 'DESTINATION_BEFORE_TRUCK_ARRIVAL',
    DESTINATION_AFTER_TRUCK_ARRIVAL_FOR_UNLOADING: 'DESTINATION_AFTER_TRUCK_ARRIVAL_FOR_UNLOADING',
    ORIGIN_AFTER_TRUCK_ARRIVAL_FOR_LOADING: 'ORIGIN_AFTER_TRUCK_ARRIVAL_FOR_LOADING',
    ORIGIN_BEFORE_TRUCK_ARRIVAL: 'ORIGIN_BEFORE_TRUCK_ARRIVAL',
    MULTISHIPMENT_TO_SINGLE_SHIPMENT: 'MULTISHIPMENT_TO_SINGLE_SHIPMENT',
    SINGLE_SHIPMENT_TO_MULTISHIPMENT: 'SINGLE_SHIPMENT_TO_MULTISHIPMENT',
  },
  [DO_CANCELLATION_REASONS_V2_CATEGORY.DO_ALLOCATION]: {
    ALLOCATED_TO_COMPETITOR: 'ALLOCATED_TO_COMPETITOR',
    BAD_SHIPPER_PRICING: 'BAD_SHIPPER_PRICING',
    NO_BUCKET: 'NO_BUCKET',
  },
  [DO_CANCELLATION_REASONS_V2_CATEGORY.INTERNAL_ISSUE]: {
    CHANGE_SHIPPER_PROFILE: 'CHANGE_SHIPPER_PROFILE',
    WRONG_ASSIGNED_BY_FULFILLMENT_TRANSPORTER_PROFILE: 'WRONG_ASSIGNED_BY_FULFILLMENT_TRANSPORTER_PROFILE',
    WRONG_DRIVER_DATA_BY_FULFILLMENT: 'WRONG_DRIVER_DATA_BY_FULFILLMENT',
    WRONG_TRANSPORTER_PRICE_BY_FULFILLMENT: 'WRONG_TRANSPORTER_PRICE_BY_FULFILLMENT',
    WRONG_COMPLETED_BY_MONITORING: 'WRONG_COMPLETED_BY_MONITORING',
    WRONG_UNFULL_BY_TP_ADMIN: 'WRONG_UNFULL_BY_TP_ADMIN',
    WRONG_POL_POD_UPLOAD_BY_MONITORING_POD: 'WRONG_POL_POD_UPLOAD_BY_MONITORING_POD',
    WRONG_PAYMENT_SCHEME_BY_ACCOUNT_PAYMENT: 'WRONG_PAYMENT_SCHEME_BY_ACCOUNT_PAYMENT',
    CHANGE_TRANSPORTER_PROFILE: 'CHANGE_TRANSPORTER_PROFILE',
    TIP_ERROR: 'TIP_ERROR',
    CHANGE_DRY_RUN: 'CHANGE_DRY_RUN',
    PAYMENT_LIMIT: 'PAYMENT_LIMIT',
  },
  [DO_CANCELLATION_REASONS_V2_CATEGORY.AUDIT_BY_AM]: {
    LONG_QUEING_AT_UNLOADING_WAREHOUSE: 'LONG_QUEING_AT_UNLOADING_WAREHOUSE',
    LONG_QUEING_AT_LOADING_WAREHOUSE: 'LONG_QUEING_AT_LOADING_WAREHOUSE',
    SHIPPER_OVERDUE_PAYMENT: 'SHIPPER_OVERDUE_PAYMENT',
    ON_NEGOTIATION_WITH_SHIPPER_REGARDING_SHIPPER_PRICE: 'ON_NEGOTIATION_WITH_SHIPPER_REGARDING_SHIPPER_PRICE',
    WRONG_SHIPPER_PRICE: 'WRONG_SHIPPER_PRICE',
  },
  [DO_CANCELLATION_REASONS_V2_CATEGORY.SHIPMENT_RESCHEDULE]: {
    EARLY_PICKUP_DATE: 'EARLY_PICKUP_DATE',
    DELAYED_PICK_UP_DATE: 'DELAYED_PICK_UP_DATE',
    EARLY_PICKUP_TIME: 'EARLY_PICKUP_TIME',
    DELAYED_PICK_UP_TIME: 'DELAYED_PICK_UP_TIME',
  },
  [DO_CANCELLATION_REASONS_V2_CATEGORY.SHIPPER_ISSUE]: {
    OVERCAPACITY: 'OVERCAPACITY',
    PRODUCTION_ISSUE: 'PRODUCTION_ISSUE',
    PURCHASE_ORDER_IS_CANCELLED: 'PURCHASE_ORDER_IS_CANCELLED',
    CHANGE_SHIPPER_PRICE: 'CHANGE_SHIPPER_PRICE',
    CANCELLED_PROJECT: 'CANCELLED_PROJECT',
    WRONG_TRUCK_SPEC: 'WRONG_TRUCK_SPEC',
    CHANGE_TRUCK_TYPE: 'CHANGE_TRUCK_TYPE',
    CHANGE_TONNAGE: 'CHANGE_TONNAGE',
  },
  [DO_CANCELLATION_REASONS_V2_CATEGORY.TECHNICAL_ISSUE]: {
    RE_UPLOAD_DO_TO_PANTHERA_FOR_MARKETPLACE: 'RE_UPLOAD_DO_TO_PANTHERA_FOR_MARKETPLACE',
    PANTHERA_ERROR_BUG: 'PANTHERA_ERROR_BUG',
  },
  [DO_CANCELLATION_REASONS_V2_CATEGORY.TRANSPORTER_ISSUE]: {
    WRONG_BID_BY_TRANSPORTER: 'WRONG_BID_BY_TRANSPORTER',
    CHANGE_TRUCK_TYPE: 'CHANGE_TRUCK_TYPE',
    CHANGE_PAYMENT_SCHEME: 'CHANGE_PAYMENT_SCHEME',
    CHANGE_TRANSPORTER_PRICE: 'CHANGE_TRANSPORTER_PRICE',
    WRONG_ASSIGNED_UNIT_BY_TRANSPORTER: 'WRONG_ASSIGNED_UNIT_BY_TRANSPORTER',
  },
  [DO_CANCELLATION_REASONS_V2_CATEGORY.WRONG_INPUT_BY_CS]: {
    SHIPPER_NAME: 'SHIPPER_NAME',
    SHIPMENT_LOCATION: 'SHIPMENT_LOCATION',
    PICKUP_DATE_OR_TIME: 'PICKUP_DATE_OR_TIME',
    DROP_OFF_DATE_OR_TIME: 'DROP_OFF_DATE_OR_TIME',
    SHIPMENT_TYPE: 'SHIPMENT_TYPE',
    TRUCK_TYPE: 'TRUCK_TYPE',
    CAPACITY: 'CAPACITY',
    NOTES: 'NOTES',
    DUPLICATE_DELIVERY_ORDER: 'DUPLICATE_DELIVERY_ORDER',
    CHANGE_CONFIRMATION_TIME: 'CHANGE_CONFIRMATION_TIME',
    TAGGING: 'TAGGING',
    SHIPPER_PRICING: 'SHIPPER_PRICING',
    TRANSPORTER_PRICING: 'TRANSPORTER_PRICING',
  },
};

export const DTD_CONTAINER_CANCELLATION_REASONS = {
  SHIPPER_REQUEST: 'SHIPPER_REQUEST',
  CHANGE_STUFFING_LOCATION: 'CHANGE_STUFFING_LOCATION',
  CHANGE_DESTINATION: 'CHANGE_DESTINATION',
  CHANGE_STUFFING_DATE: 'CHANGE_STUFFING_DATE',
  OTHER: 'OTHER',
};

export const DTD_TRUCKING_CANCELLATION_REASONS = {
  FORCE_MAJEURE: 'FORCE_MAJEURE',
  CONTAINER_MISPLACED: 'CONTAINER_MISPLACED',
  CONTAINER_REJECTED_BY_SHIPPER: 'CONTAINER_REJECTED_BY_SHIPPER',
  FULL_STOCK: 'FULL_STOCK',
  NO_STOCK: 'NO_STOCK',
  LOADING_DELAY: 'LOADING_DELAY',
  REVISION_ALLOCATION: 'REVISION_ALLOCATION',
  SAFETY_QUALITY_COMPLIANCE: 'SAFETY_QUALITY_COMPLIANCE',
  TKBM: 'TKBM',
  OTHER_REASON: 'OTHER_REASON',
};

export const DTD_TRUCKING_UNIFIED_CANCELLATION_REASON = {
  ...DTD_CONTAINER_CANCELLATION_REASONS,
  ...DTD_TRUCKING_CANCELLATION_REASONS,
};

export const COMPANY_ADDRESS_TYPES = {
  BILLING: 'BILLING',
  COMPANY: 'COMPANY',
};

export const SHIPPER_COMPANY_DOCUMENT_TYPES = {
  TDP: 'TDP',
  KTP: 'KTP',
  'SIUP/SIUJPT': 'SIUP/SIUJPT',
  NPWP: 'NPWP',
  PKP: 'PKP',
};

export const COMPANY_PARTNER_TYPE = {
  PARTNER: 'PARTNER',
  COMPANY: 'COMPANY',
};

export const SHOW_CLAIMS_IN = {
  POD: 'POD',
  BERITA_ACARA: 'BERITA_ACARA',
  LOAD_ITEMS: 'LOAD_ITEMS',
};

export const IS_RETURN_GOODS = {
  NO_RETURN: 'NO_RETURN',
  RETURN_GOODS: 'RETURN_GOODS',
};

export const GOODS_REJECTION_CATEGORY = {
  CLAIM: 'CLAIM',
  NO_CLAIM: 'NO_CLAIM',
  REPACKING: 'REPACKING',
};

export const IS_RETURN_FEE_COVERED = {
  NEGOTIABLE: 'NEGOTIABLE',
  NOT_COVERED: 'NOT_COVERED',
};

export const OTP_OPTIONS = {
  REGISTRATION: 'REGISTRATION',
  RESET_PASSWORD: 'RESET_PASSWORD',
  FIRST_TIME_LOGIN: 'FIRST_TIME_LOGIN',
};

export const PLATE_COLORS = {
  BLACK_PLATE: 'BLACK_PLATE',
  YELLOW_PLATE: 'YELLOW_PLATE',
};

export const LOCATION_TYPE = {
  NORMAL: 'NORMAL',
  HOLDING: 'HOLDING',
  PORT: 'PORT',
  RETURN_DEPO: 'RETURN_DEPO',
  STORE: 'STORE',
};

export const ROUTE_LOCATION_GROUP = {
  PORT_STORE_RETURN_DEPO: [
    LOCATION_TYPE.PORT,
    LOCATION_TYPE.STORE,
    LOCATION_TYPE.RETURN_DEPO,
  ],
  PORT_HOLDING: [LOCATION_TYPE.PORT, LOCATION_TYPE.HOLDING],
  HOLDING_STORE_RETURN_DEPO: [
    LOCATION_TYPE.HOLDING,
    LOCATION_TYPE.STORE,
    LOCATION_TYPE.RETURN_DEPO,
  ],
};

export const DO_PAYMENT_STATUS = {
  DOCUMENT_PENDING: 'document_pending',
  DOCUMENT_RECEIVED: 'document_received',
  ON_PROCESS: 'on_process',
  PAID: 'paid',
};

export const DO_PAYMENT_STATUS_COLOR = {
  [DO_PAYMENT_STATUS.DOCUMENT_PENDING]: 'grey',
  [DO_PAYMENT_STATUS.DOCUMENT_RECEIVED]: 'primary',
  [DO_PAYMENT_STATUS.ON_PROCESS]: 'warning',
  [DO_PAYMENT_STATUS.PAID]: 'success',
};

export const PAYMENT_TYPES = {
  SHIPMENT_FEE: 'SHIPMENT_FEE',
  PPN: 'PPN',
  LOADING_FEE: 'LOADING_FEE',
  UNLOADING_FEE: 'UNLOADING_FEE',
  OVERNIGHT_FEE: 'OVERNIGHT_FEE',
  TICKET_FEE: 'TICKET_FEE',
  OTHER_FEE: '',
};

export const ADDITIONAL_PAYMENT_TYPES = [
  PAYMENT_TYPES.LOADING_FEE,
  PAYMENT_TYPES.UNLOADING_FEE,
  PAYMENT_TYPES.OVERNIGHT_FEE,
  PAYMENT_TYPES.TICKET_FEE,
  PAYMENT_TYPES.OTHER_FEE,
];

export const PAYMENT_TYPE_AS_SHIPMENT_PRICE = [
  PAYMENT_TYPES.ON_CALL,
  PAYMENT_TYPES.OPERATIONAL_FEE,
  PAYMENT_TYPES.DRY_RUN,
];

export const TAX_TYPES = {
  INCOME_TAX: 'income_tax',
  VAT: 'vat',
};

export const NEGATIVE_TAXES = [TAX_TYPES.INCOME_TAX];

export const SERVICES_URL = {
  PANTHERA: window.PANTHERA_SERVICE_URL,
  LOAD: window.LOAD_SERVICE_URL,
  EVENT: window.EVENT_SERVICE_URL,
  PROFILE: window.PROFILE_SERVICE_URL,
  MASTER_SERVICE: window.MASTER_SERVICE_URL,
  AUTH: window.AUTH_SERVICE_URL,
  PROFILE_PUBLIC: window.PROFILE_SERVICE_URL_PUBLIC,
  PAYMENT: window.PAYMENT_SERVICE_URL,
  PROFILE_TRANSPORTER_URL: window.PROFILE_TRANSPORTER_URL,
  FRAUD: window.FRAUD_SERVICE_URL,
  SHIPPER_ORDER_MANAGEMENT: window.SHIPPER_ORDER_MANAGEMENT_URL,
};

export const EVENT_SERVICE_DEVICE_TYPE = 'transporter_central';

export const VEHICLE_STATUSES = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  REJECTED: 'REJECTED',
  REGISTERED: 'REGISTERED',
  DEACTIVATED: 'DEACTIVATED',
};

export const COMPANY_VEHICLE_STATUSES = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  REJECTED: 'REJECTED',
};

export const VEHICLE_STATUS_COLORS = {
  [VEHICLE_STATUSES.DRAFT]: 'darkGrey',
  [VEHICLE_STATUSES.PENDING]: 'warning',
  [VEHICLE_STATUSES.ACTIVE]: 'success',
  [VEHICLE_STATUSES.DEACTIVATED]: 'danger',
  [VEHICLE_STATUSES.REGISTERED]: 'success',
  [VEHICLE_STATUSES.EXPIRED]: 'danger',
  [VEHICLE_STATUSES.REJECTED]: 'danger',
};

export const DRIVER_STATUSES = {
  REGISTERED: 'REGISTERED',
  DEACTIVATED: 'DEACTIVATED',
  SUSPENDED: 'SUSPENDED',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  UPCOMING: 'UPCOMING',
  CREATED: 'CREATED',
  DOCUMENT_EXPIRED: 'DOCUMENT_EXPIRED',
};

export const SHIPPER_EMPLOYEE_STATUSES = {
  REGISTERED: 'REGISTERED',
  DEACTIVATED: 'DEACTIVATED',
  SUSPENDED: 'SUSPENDED',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  CREATED: 'CREATED',
  DOCUMENT_EXPIRED: 'DOCUMENT_EXPIRED',
};

export const COMPANY_DRIVER_STATUSES = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  DOCUMENT_EXPIRED: 'DOCUMENT_EXPIRED',
  DRAFT: 'DRAFT',
};

export const PARTNER_COMPANY_DRIVER_STATUSES = {
  REGISTERED: 'REGISTERED',
  DEACTIVATED: 'DEACTIVATED',
};

export const PARTNER_COMPANY_VEHICLE_STATUSES = {
  REGISTERED: 'REGISTERED',
  DEACTIVATED: 'DEACTIVATED',
};

export const COMPANY_DRIVER_STATUS_COLORS = {
  [COMPANY_DRIVER_STATUSES.ACTIVE]: 'success',
  [COMPANY_DRIVER_STATUSES.PENDING]: 'warning',
  [COMPANY_DRIVER_STATUSES.REJECTED]: 'danger',
  [COMPANY_DRIVER_STATUSES.DRAFT]: 'darkGrey',
  [COMPANY_DRIVER_STATUSES.DEACTIVATED]: 'danger',
  [COMPANY_DRIVER_STATUSES.REGISTERED]: 'success',
};

export const DRIVER_STATUS_COLORS = {
  [DRIVER_STATUSES.SUSPENDED]: 'danger',
  [DRIVER_STATUSES.DEACTIVATED]: 'danger',
  [DRIVER_STATUSES.ACTIVE]: 'success',
  [DRIVER_STATUSES.REGISTERED]: 'success',
  [DRIVER_STATUSES.UPCOMING]: 'success',
  [DRIVER_STATUSES.INACTIVE]: 'danger',
  [DRIVER_STATUSES.CREATED]: 'darkGrey',
  [DRIVER_STATUSES.DOCUMENT_EXPIRED]: 'danger',
};

export const USER_STATUSES = {
  ACTIVE: 'ACTIVE',
  REGISTERED: 'REGISTERED',
  INACTIVE: 'INACTIVE',
};

export const USER_STATUS_COLORS = {
  [USER_STATUSES.ACTIVE]: 'success',
  [USER_STATUSES.REGISTERED]: 'warning',
  [USER_STATUSES.INACTIVE]: 'danger',
};

export const NON_EDITABLE_USER_STATUSES = [USER_STATUSES.BLOCKED];

export const INVOICE_TEMPLATE_TYPES = {
  DEFAULT_TEMPLATE: 'DEFAULT_TEMPLATE',
};

export const DOCUMENT_TYPES = {
  POD: 'POD',
  POL: 'POL',
  CLAIMS_AND_RETURNS: 'CAR',
  CLAIM_AND_RETURN_DOCUMENT: 'CLAIM_AND_RETURN_DOCUMENT',
  CLAIM_AND_RETURN_GOODS: 'CLAIM_AND_RETURN_GOODS',
  DRIVER_REJECTED_GOODS: 'REJECTED_GOODS',
  DRIVER_REJECTION_LETTER: 'REJECTION_LETTER',
  PROOF_OF_APPROVAL_LOWER_PRICE: 'PROOF_OF_APPROVAL_LOWER_PRICE',
  PROOF_OF_APPROVAL_ROUTE_OUTSIDE_RANGE: 'PROOF_OF_APPROVAL_ROUTE_OUTSIDE_RANGE',
  RC: 'RC',
};

export const SHIPMENT_DOCUMENT_TYPES = {
  INTERNAL_POD: 'internal-pod',
  INTERNAL_POL: 'internal-pol',
  CLAIMS_AND_RETURNS: 'claims-returns',
};

export const DOCUMENT_STATUSES = {
  PENDING: 'pending',
  REJECTED: 'rejected',
  SUBMITTED: 'submitted',
  APPROVED: 'approved',
  NEGLECTED: 'neglected',
  DISCARDED: 'discarded',
  IGNORED: 'ignored',
  DRAFT: 'draft',
};

export const DOCUMENT_VERIFICATION_STATUSES = {
  ACCEPTED: 'ACCEPTED',
  APPROVED: 'APPROVED',
  DRAFT: 'DRAFT',
  EXPIRED: 'EXPIRED',
  NEGLECTED: 'NEGLECTED',
  PENDING: 'PENDING',
  REGISTERED: 'REGISTERED',
  REJECTED: 'REJECTED',
};

export const DOCUMENT_VERIFICATION_STATUS_COLORS = {
  [DOCUMENT_VERIFICATION_STATUSES.PENDING]: 'darkGrey',
  [DOCUMENT_VERIFICATION_STATUSES.ACCEPTED]: 'success',
  [DOCUMENT_VERIFICATION_STATUSES.APPROVED]: 'success',
  [DOCUMENT_VERIFICATION_STATUSES.REJECTED]: 'danger',
  [DOCUMENT_VERIFICATION_STATUSES.EXPIRED]: 'danger',
};

export const TRANSPORTER_COMPANY_STATUSES = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  SUSPENDED: 'SUSPENDED',
  BLOCKED: 'BLOCKED',
};

export const DEACTIVATED_TRANSPORTER_COMPANY_STATUSES = [
  TRANSPORTER_COMPANY_STATUSES.SUSPENDED,
  TRANSPORTER_COMPANY_STATUSES.BLOCKED,
];

export const INACTIVE_TRANSPORTER_COMPANY_STATUSES = [
  TRANSPORTER_COMPANY_STATUSES.DRAFT,
  TRANSPORTER_COMPANY_STATUSES.PENDING,
];

export const TRANSPORTER_COMPANY_STATUS_COLORS = {
  [TRANSPORTER_COMPANY_STATUSES.ACTIVE]: 'success',
  [TRANSPORTER_COMPANY_STATUSES.PENDING]: 'warning',
  [TRANSPORTER_COMPANY_STATUSES.REJECTED]: 'danger',
  [TRANSPORTER_COMPANY_STATUSES.SUSPENDED]: 'danger',
  [TRANSPORTER_COMPANY_STATUSES.BLOCKED]: 'danger',
  [TRANSPORTER_COMPANY_STATUSES.DRAFT]: 'secondaryDark',

};

export const LOCALE_TYPE = {
  id: 'id',
  en: 'en',
};

export const DOCUMENT_STATUS_COLORS = {
  [DOCUMENT_STATUSES.PENDING]: 'warning',
  [DOCUMENT_STATUSES.REJECTED]: 'danger',
  [DOCUMENT_STATUSES.SUBMITTED]: 'warning',
  [DOCUMENT_STATUSES.APPROVED]: 'success',
  [DOCUMENT_STATUSES.NEGLECTED]: 'warning',
  [DOCUMENT_STATUSES.DISCARDED]: 'danger',
};

export const COMPANY = {
  PANTHERA: {
    NAME: 'PT. Panthera Biru Indonesia',
    // eslint-disable-next-line max-len
    ADDRESS: `Jl. Jend. Sudirman No. 52-53, RT.5/RW.3,
      Senayan, Kby. Baru, Jakarta, Daerah Khusus
      Ibukota Jakarta 12190 (Equity Tower)`,
    PHONE: '0813-3619-1764',
    NPWP: '90.973.762.9-012.000',
    WEBSITE: '',
    EMAIL: '',
    BANK: {
      NAME: 'Bank Central Asia',
      BRANCH: 'KCP. SCBD',
      ACCOUNT_NUMBER: '006-059-9092',
      ACCOUNT_NAME: 'PANTHERA BIRU INDONESIA PT',
    },
  },
  KARGO: {
    NAME: 'PT. Kargo Online System',
    // eslint-disable-next-line max-len
    ADDRESS: `Equity Tower Lt. 49 Unit B, C, & F
    Jl. Jend. Sudirman Kav. 52-53 Lot 9 SCBD, Senayan,
    Kebayoran Baru, Jakarta Selatan`,
    PHONE: '0813-3619-1764',
    NPWP: '76.302.054.2-028.000',
    WEBSITE: 'kargo.tech',
    EMAIL: '',
    BANK: {
      NAME: 'Bank Central Asia',
      BRANCH: 'KCP. Abdul Muis',
      ACCOUNT_NUMBER: '655-005-8985',
      ACCOUNT_NAME: 'KARGO ONLINE SYSTEM PT',
    },
  },
};

export const PANTHERA_ENTITY = {
  PBI: {
    NAME: 'PT. Panthera Biru Indonesia',
    ALIAS: 'PBI',
    // eslint-disable-next-line max-len
    ADDRESS: 'Plaza Asia, Office Park Unit III, Jl. Jend. Sudirman Kav. 59, Kelurahan Senayan, Kecamatan Kebayoran Baru, Jakarta Selatan, DKI Jakarta 12190',
    NPWP: '90.973.762.9-012.000',
  },
  KOS: { NAME: 'PT. Kargo Online System', ALIAS: 'KOS' },
  PUS: { NAME: 'PT. Panthera Usaha Satu', ALIAS: 'PUS' },
  TBK: { NAME: 'PT. Tumbuh Bersama Kargo', ALIAS: 'TBK' },
  TLOID: { NAME: 'PT. The Lorry Online Indonesia', ALIAS: 'TLOID' },
  TLO: { NAME: 'The Lorry Online Sdn Bhd', ALIAS: 'TLO' },
};

export const CUSTOMER_DIVISION = {
  OPERATIONS: 'OPERATIONS',
  WAREHOUSE: 'WAREHOUSE',
  SALES: 'SALES',
  FINANCE: 'FINANCE',
  PROCUREMENT: 'PROCUREMENT',
  LOGISTICS: 'LOGISTICS',
  TREASURY: 'TREASURY',
  CLAIMS: 'CLAIMS',
};

export const PANTHERA_PIC_DIVISION = {
  SALES: 'SALES',
  SALES_SUPPORT: 'SALES_SUPPORT',
  ACCOUNT_RECEIVABLE: 'ACCOUNT_RECEIVABLE',
  CANVASSER: 'CANVASSER',
};

export const SHIPPER_NAVIGATION_MANAGEMENT_TAB = {
  ENTERPRISES: 'enterprises',
  SME: 'smes',
  LOCATIONS: 'locations',
};

export const WEIGHT_UNITS = {
  TON: 'TON',
  CBM: 'CBM',
};

export const PPH23_DEDUCTOR = {
  PARTNER: 'PARTNER',
  SELF: 'SELF',
};

export const LAST_DISBURSEMENT_PPH23_DEDUCTOR = {
  SHIPPER: 'SHIPPER',
  TRANSPORTER: 'TRANSPORTER',
};

export const SHIPMENT_FORM_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  ALLOCATE: 'ALLOCATE',
  REALLOCATE: 'REALLOCATE',
  UPDATE_DO_NUMBER: 'UPDATE_DO_NUMBER',
  CHOOSE_WINNER: 'CHOOSE_WINNER',
  ASSIGN_SHIPMENT: 'ASSIGN_SHIPMENT',
  QUEUE_SHIPMENT: 'QUEUE_SHIPMENT',
  SWITCH_SHIPMENT: 'SWITCH_SHIPMENT',
  CREATE_REMINDER: 'CREATE_REMINDER',
  REOPEN_JOB: 'REOPEN_JOB',
  CANCEL_SHIPMENT: 'CANCEL_SHIPMENT',
  UNFULFILLMENT_SHIPMENT: 'UNFULFILLMENT_SHIPMENT',
  CANCEL_MARKETPLACE_JOB: 'CANCEL_MARKETPLACE_JOB',
  APPROVE_REASSIGNMENT: 'APPROVE_REASSIGNMENT',
  SET_BID_BASE_PRICE: 'SET_BID_BASE_PRICE',
  SET_BID_WINNER: 'SET_BID_WINNER',
  ADD_WHITELISTED_TRANSPORTER: 'ADD_WHITELISTED_TRANSPORTER',
};

export const PAYMENT_REQUEST_TYPES = {
  REVIEW: 'REVIEW',
  REQUEST: 'REQUEST',
  DETAILS: 'DETAILS',
  PDF: 'PDF',
};

export const PAYMENT_REQUEST_REJECTION_TYPE = {
  INVOICE_PROBLEM: 'INVOICE_PROBLEM',
  DELIVERY_ORDER_PROBLEM: 'DELIVERY_ORDER_PROBLEM',
  REQUEST_FROM_AP: 'REQUEST_FROM_AP',
};

export const SHIPMENT_CONFIRMATION_FORM_TYPES = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
  SHOW_ALREADY_ASSIGN_WARNING: 'SHOW_ALREADY_ASSIGN_WARNING',
};

export const SHIPPER_FORM_TYPES = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  CONFIRM_NFS: 'CONFIRM_NFS',
};

export const SHIPMENT_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  ALLOCATION_FORM: 'ALLOCATION_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY',
};

export const SME_PAYMENT_STATUS = {
  PAID: 'PAID',
  NOT_PAID: 'NOT_PAID',
};

export const SHIPMENT_ACTIVITY_TYPES = {
  ASSIGNED_ONGOING_TO_PICKUP: 'ASSIGNED_ONGOING_TO_PICKUP',
  ONGOING_TO_PICKUP_ARRIVE_AT_PICKUP: 'ONGOING_TO_PICKUP_ARRIVE_AT_PICKUP',
  ARRIVE_AT_PICKUP_START_LOADING: 'ARRIVE_AT_PICKUP_START_LOADING',
  START_LOADING_FINISH_LOADING: 'START_LOADING_FINISH_LOADING',
  FINISH_LOADING_ON_SHIPMENT: 'FINISH_LOADING_ON_SHIPMENT',
  ON_SHIPMENT_ARRIVE_AT_DESTINATION: 'ON_SHIPMENT_ARRIVE_AT_DESTINATION',
  ARRIVE_AT_DESTINATION_START_UNLOADING: 'ARRIVE_AT_DESTINATION_START_UNLOADING',
};

export const DERIVED_SHIPMENT_STATUSES = {
  MAIN_SHIPMENT: 'MAIN_SHIPMENT',
  BEHIND_THE_SCHEDULE: 'BEHIND_THE_SCHEDULE',
  ON_TIME: 'ON_TIME',
  QUEUEING_AT_ORIGIN: 'QUEUEING_AT_ORIGIN',
  QUEUEING_AT_DESTINATION: 'QUEUEING_AT_DESTINATION',
  QUEUEING_OVERTIME: 'QUEUEING_OVERTIME',
  CANCELLED_BY_INTERNAL: 'CANCELLED_BY_INTERNAL',
  QUEUE_AT_ORIGIN_LATE: 'QUEUE_AT_ORIGIN_LATE',
  QUEUE_AT_DESTINATION_LATE: 'QUEUE_AT_DESTINATION_LATE',
};

export const JOB_STATUSES = {
  OPEN: 'OPEN',
  WAITING_FOR_CONFIRMATION: 'WAITING_FOR_CONFIRMATION',
  JOB_BIDDING: 'JOB_BIDDING',
  JOB_UNASSIGNED: 'JOB_UNASSIGNED',
  LATE_TO_ORIGIN: 'LATE_TO_ORIGIN',
  LATE_TO_DESTINATION: 'LATE_TO_DESTINATION',
  JOB_TRANSPORTER_CONFIRMED: 'JOB_TRANSPORTER_CONFIRMED',
  WAITING_FOR_JOB_CONFIRMATION: 'WAITING_FOR_JOB_CONFIRMATION',
  TRANSPORTER_CONFIRMED: 'TRANSPORTER_CONFIRMED',
  BIDDING: 'BIDDING',
  CANCELLED: 'CANCELLED',
  PLANNED: 'PLANNED',
  ASSIGNED: 'ASSIGNED',
  UNASSIGNED: 'UNASSIGNED',
  CANCELLED_BY_TRANSPORTER: 'CANCELLED_BY_TRANSPORTER',
  CONFIRMATION_EXPIRED: 'CONFIRMATION_EXPIRED',
  JOB_WAITING_FOR_SHIPPER_CONFIRMATION: 'JOB_WAITING_FOR_SHIPPER_CONFIRMATION',
};

export const SHIPMENT_STATUS_SEQUENCE = [
  SHIPMENT_STATUSES.UPCOMING,
  SHIPMENT_STATUSES.PLANNED,
  SHIPMENT_STATUSES.ASSIGNED,
  SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
  SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
  SHIPMENT_STATUSES.START_LOADING,
  SHIPMENT_STATUSES.FINISH_LOADING,
  SHIPMENT_STATUSES.ON_SHIPMENT,
  SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
  SHIPMENT_STATUSES.START_UNLOADING,
  SHIPMENT_STATUSES.FINISH_UNLOADING,
  SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION,
  SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
];

export const SHIPPER_STATUSES = {
  CHURN: 'CHURN',
  PENDING_ONBOARDING: 'PENDING_ONBOARDING',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  SUSPENDED: 'SUSPENDED',
  REJECTED: 'REJECTED',
  REGISTERED: 'REGISTERED',
};

export const PAYMENT_REQUEST_STATUSES = {
  REVIEW_NOW: 'REVIEW_NOW',
  APPROVED: 'APPROVED',
  ON_HOLD: 'ON_HOLD',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  WAITING_FOR_WITHDRAWAL: 'WAITING_FOR_WITHDRAWAL',
  WITHDRAWAL_REQUESTED: 'WITHDRAWAL_REQUESTED',
  DISBURSEMENT_PLANNED: 'DISBURSEMENT_PLANNED',
  PAYMENT_PROCESSING: 'PAYMENT_PROCESSING',
  PROCESSING: 'PROCESSING',
  DISBURSED: 'DISBURSED',
  DISBURSEMENT_FAILED: 'DISBURSEMENT_FAILED',
};

export const PAYMENT_REQUEST_WITHDRAWAL_STATUS = [
  PAYMENT_REQUEST_STATUSES.WAITING_FOR_WITHDRAWAL,
  PAYMENT_REQUEST_STATUSES.WITHDRAWAL_REQUESTED,
];

export const PAYMENT_REQUEST_DISBURSABLE_STATUS = [
  PAYMENT_REQUEST_STATUSES.APPROVED,
  PAYMENT_REQUEST_STATUSES.DISBURSEMENT_FAILED,
];

export const NEW_PAYMENT_REQUEST_DISBURSABLE_STATUS = [
  PAYMENT_REQUEST_STATUSES.DISBURSEMENT_FAILED,
  PAYMENT_REQUEST_STATUSES.WITHDRAWAL_REQUESTED,
];

export const SHIPPER_LOCATION_STATUS = {
  ACTIVE: 'REGISTERED',
  INACTIVE: 'DEACTIVATED',
  REGISTERED: 'REGISTERED',
};

export const SME_PRODUCT_CATEGORY = {
  AUTOMOTIVE: 'Mobil / Motor',
  MOVING_GOODS: 'Barang Pindahan',
  CONSTRUCTION_MATERIAL: 'Bahan Konstruksi',
  HEAVY_EQUIPMENT: 'Alat Berat',
  FROZEN_GOODS: 'Barang Beku',
  AGRICULTURAL_PRODUCT: 'Hasil Pertanian',
  GENERAL_CARGO: 'Kargo Umum',
  GLASSWARE: 'Pecah Belah',
  PRODUCT_PACKAGING: 'Produk Kemasan',
};

export const PRODUCT_CATEGORY = {
  ALCOHOLIC_BEVARAGES: 'ALCOHOLIC_BEVARAGES',
  DRINKING_WATER: 'DRINKING_WATER',
  COFFEE: 'COFFEE',
  FRUIT_FLAVORED_DRINKS: 'FRUIT_FLAVORED_DRINKS',
  JUICE: 'JUICE',
  MILK: 'MILK',
  NON_DAIRY: 'NON_DAIRY',
  POWDERED_BEVERAGES_MIXES: 'POWDERED_BEVERAGES_MIXES',
  SODA: 'SODA',
  SPORTS_ENERGY_DRINKS: 'SPORTS_ENERGY_DRINKS',
  TEA_INFUSIONS: 'TEA_INFUSIONS',
  WATER: 'WATER',
  BAKERY: 'BAKERY',
  SNAKES: 'SNAKES',
  CONDIMENTS_SOUCES: 'CONDIMENTS_SOUCES',
  COOKING_BAKING_INGREDIENTS: 'COOKING_BAKING_INGREDIENTS',
  DAIRY: 'DAIRY',
  DIPS: 'DIPS',
  FOOD: 'FOOD',
  FROZEN: 'FROZEN',
  FRUITS: 'FRUITS',
  GRAINS: 'GRAINS',
  MEAT: 'MEAT',
  NUTS: 'NUTS',
  PASTA: 'PASTA',
  PREPARED: 'PREPARED',
  SPICES: 'SPICES',
  SNACKS: 'SNACKS',
  SOUPS: 'SOUPS',
  TOUFU: 'TOUFU',
  FITNESS_AND_NUTRITION: 'FITNESS_AND_NUTRITION',
  MEDICINE_AND_DRUGS: 'MEDICINE_AND_DRUGS',
  FMCG: 'FMCG',
  PLASTIC: 'PLASTIC',
  PACKAGING: 'PACKAGING',
};

export const SHIPPER_TYPES = {
  A1: 'A1',
  A2: 'A2',
  A3: 'A3',
};

export const INDUSTRY_OLD = {
  FMCG: 'FMCG',
  RAW_MATERIAL_OR_ORM: 'RAW_MATERIAL_OR_ORM',
  LOGISTIC_COMPANY: 'LOGISTIC_COMPANY',
  BROKER_TRANSPORTER: 'BROKER_TRANSPORTER',
  E_COMMERCE: 'E_COMMERCE',
  COMMODITY_AND_TRADING: 'COMMODITY_AND_TRADING',
};

export const INDUSTRY = {
  FMCG: 'FMCG',
  RAW_MATERIAL_OR_ORM: 'RAW_MATERIAL_OR_ORM',
  LOGISTIC_COMPANY: 'LOGISTIC_COMPANY',
  BROKER_TRANSPORTER: 'BROKER_TRANSPORTER',
  E_COMMERCE: 'E_COMMERCE',
  COMMODITY_AND_TRADING: 'COMMODITY_AND_TRADING',
  CPG: 'CPG',
  TOBACCO: 'TOBACCO',
  CONSTRUCTION: 'CONSTRUCTION',
  AGRICULTURE: 'AGRICULTURE',
  MINING: 'MINING',
  AQUACULTURE: 'AQUACULTURE',
  OIL_AND_GAS: 'OIL_AND_GAS',
  TEXTILE: 'TEXTILE',
  ELECTRONICS: 'ELECTRONICS',
  AUTOMOTIVE: 'AUTOMOTIVE',
  PHARMACEUTICAL: 'PHARMACEUTICAL',
  TELECOMMUNICATION: 'TELECOMMUNICATION',
};

export const SME_INDUSTRY = {
  AUTOMOTIVE: 'Automotif',
  RECYLING: 'Bahan Daur Ulang',
  CONSTRUCTION: 'Bangunan',
  COSMETIC: 'Cosmetic/kecantikan',
  ELECTRONIC: 'Elektronik',
  FMCG: 'FMCG',
  FURNITURE: 'Furniture',
  HOTEL: 'Hotel',
  LUMBER: 'Kayu',
  HEALTHCARE: 'Kesehatan',
  LOGISTICS: 'Logistic',
  CLOTHES: 'Pakaian & Aksesoris',
  AGRICULTURE: 'Pertanian',
  PERSONAL: 'Pribadi',
  TEXTILE: 'Textile & Garment',
};

export const SHIPPER_STATUS_COLORS = {
  [SHIPPER_STATUSES.CHURN]: 'danger',
  [SHIPPER_STATUSES.INACTIVE]: 'warning',
  [SHIPPER_STATUSES.ACTIVE]: 'success',
  [SHIPPER_STATUSES.PENDING_ONBOARDING]: 'grey',
  [SHIPPER_STATUSES.SUSPENDED]: 'danger',
  [SHIPPER_STATUSES.REJECTED]: 'danger',
};

export const PAYMENT_REQUEST_STATUS_COLORS = {
  [PAYMENT_REQUEST_STATUSES.APPROVED]: 'primary',
  [PAYMENT_REQUEST_STATUSES.CANCELLED]: 'danger',
  [PAYMENT_REQUEST_STATUSES.DISBURSED]: 'success',
  [PAYMENT_REQUEST_STATUSES.DISBURSEMENT_FAILED]: 'danger',
  [PAYMENT_REQUEST_STATUSES.DISBURSEMENT_PLANNED]: 'primary',
  [PAYMENT_REQUEST_STATUSES.PAYMENT_PROCESSING]: 'warning',
  [PAYMENT_REQUEST_STATUSES.ON_HOLD]: 'orangeyYellow',
  [PAYMENT_REQUEST_STATUSES.PROCESSING]: 'secondary',
  [PAYMENT_REQUEST_STATUSES.REJECTED]: 'danger',
  [PAYMENT_REQUEST_STATUSES.REVIEW_NOW]: 'orangeyYellow',
};

export const SHIPPER_LOCATION_STATUS_COLOR = {
  [SHIPPER_LOCATION_STATUS.ACTIVE]: 'success',
  [SHIPPER_LOCATION_STATUS.INACTIVE]: 'danger',
};

export const SHIPPER_LOCATION_OPERATING_DAY = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
  WEEKDAY: 'WEEKDAY',
  WEEKEND: 'WEEKEND',
  HOLIDAY: 'HOLIDAY',
};

export const FACILITIES_OPTIONS = [
  'WAITING_AREA',
  'ONSITE_SCALE',
  'PARKING_AREA',
  'FOOD_STALL',
  'TOILET',
];

export const SHIPMENT_STATUS_COLORS = {
  [SHIPMENT_STATUSES.UPCOMING]: 'danger',
  [SHIPMENT_STATUSES.PLANNED]: 'danger',
  [SHIPMENT_STATUSES.ASSIGNED]: 'darkGrey',
  [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'primary',
  [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'primary',
  [SHIPMENT_STATUSES.START_LOADING]: 'warning',
  [SHIPMENT_STATUSES.FINISH_LOADING]: 'success',
  [SHIPMENT_STATUSES.ON_SHIPMENT]: 'primary',
  [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'primary',
  [SHIPMENT_STATUSES.START_UNLOADING]: 'warning',
  [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'success',
  [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'grey',
  [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'danger',
  [SHIPMENT_STATUSES.CANCELLED]: 'danger',
  [SHIPMENT_STATUSES.UNFULFILLED]: 'danger',
  [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'danger',
  [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'danger',
  [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'danger',
  [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'danger',
  [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME]: 'danger',
  [DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE]: 'danger',
  [DERIVED_SHIPMENT_STATUSES.ON_TIME]: 'primary',
  [DERIVED_SHIPMENT_STATUSES.QUEUE_AT_ORIGIN_LATE]: 'danger',
  [DERIVED_SHIPMENT_STATUSES.QUEUE_AT_DESTINATION_LATE]: 'danger',
  [SHIPMENT_STATUSES.LATE_TO_ORIGIN]: 'danger',
  [SHIPMENT_STATUSES.LATE_TO_DESTINATION]: 'danger',
  [SHIPMENT_STATUSES.JOB_BIDDING]: 'orangeyYellow',
  [SHIPMENT_STATUSES.PLANNED]: 'steelBlue',
  [SHIPMENT_STATUSES.ASSIGNED]: 'tealish',
  [SHIPMENT_STATUSES.JOB_UNASSIGNED]: 'orangeyYellow',
  [SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION]: 'warning',
  [SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL]: 'danger',
  [SHIPMENT_STATUSES.JOB_TRANSPORTER_CONFIRMED]: 'deepSeaBlue',
  [SHIPMENT_STATUSES.QUEUED]: 'neonPurple',
  [SHIPMENT_STATUSES.JOB_WAITING_FOR_SHIPPER_CONFIRMATION]: 'orangeyYellow',
};

export const SME_PAYMENT_STATUS_COLOR = {
  [SME_PAYMENT_STATUS.PAID]: 'success',
  [SME_PAYMENT_STATUS.NOT_PAID]: 'warning',
};

export const MORE_FILTER_KEYS = {
  ORIGIN_LOCATION_FIELD: 'originWarehouseKsuid',
  DESTINATION_LOCATION_FIELD: 'destinationWarehouseKsuid',
  STUFFING_LOCATION_FIELD: 'stuffingWarehouseKsuid',
  LOADING_DATE_FIELD: 'loadingDate',
  UNLOADING_DATE_FIELD: 'unloadingDate',
  ARRIVE_AT_ORIGIN_DATE_FIELD: 'arriveAtOriginDate',
  ARRIVE_AT_DESTINATION_DATE_FIELD: 'arriveAtDestinationDate',
  STUFFING_DATE_FIELD: 'stuffingDate',
  TAGS_FIELD: 'tags',
  SUPPORT_FIELD: 'createdByKsuid',
  EXTERNAL_ID: 'externalId',
  GROUP_LOAD_NUMBER: 'groupLoadNumber',
  TRANSPORTER_COMPANY: 'transporterCompanyKsuids',
  DRIVER: 'driverKsuids',
};

export const MONITORING_POSTS = {
  beforePickup: 'beforePickup',
  pickup: 'pickup',
  polUpload: 'polUpload',
  onShipment: 'onShipment',
  atDestination: 'atDestination',
  podUpload: 'podUpload',
  ALL: 'ALL',
};

export const MONITORING_POSTS_TO_STATUS = {
  [MONITORING_POSTS.beforePickup]: [SHIPMENT_STATUSES.ASSIGNED, SHIPMENT_STATUSES.ONGOING_TO_PICKUP],
  [MONITORING_POSTS.pickup]: [
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP, SHIPMENT_STATUSES.START_LOADING, SHIPMENT_STATUSES.FINISH_LOADING,
  ],
  [MONITORING_POSTS.polUpload]: [
    SHIPMENT_STATUSES.PLANNED,
    SHIPMENT_STATUSES.ASSIGNED,
    SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.START_LOADING,
    SHIPMENT_STATUSES.FINISH_LOADING,
    SHIPMENT_STATUSES.ONGOING_TO_DESTINATION,
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.START_UNLOADING,
    SHIPMENT_STATUSES.FINISH_UNLOADING,
    SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
    SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION,
  ],
  [MONITORING_POSTS.onShipment]: [SHIPMENT_STATUSES.ON_SHIPMENT],
  [MONITORING_POSTS.atDestination]: [
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION, SHIPMENT_STATUSES.START_UNLOADING, SHIPMENT_STATUSES.FINISH_UNLOADING,
  ],
  [MONITORING_POSTS.podUpload]: [
    SHIPMENT_STATUSES.PLANNED,
    SHIPMENT_STATUSES.ASSIGNED,
    SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.START_LOADING,
    SHIPMENT_STATUSES.FINISH_LOADING,
    SHIPMENT_STATUSES.ONGOING_TO_DESTINATION,
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.START_UNLOADING,
    SHIPMENT_STATUSES.FINISH_UNLOADING,
    SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
    SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION,
  ],
  get [MONITORING_POSTS.ALL]() {
    return [
      ...this[MONITORING_POSTS.beforePickup],
      ...this[MONITORING_POSTS.pickup],
      ...this[MONITORING_POSTS.onShipment],
      ...this[MONITORING_POSTS.atDestination],
      ...[
        SHIPMENT_STATUSES.JOB_BIDDING,
        SHIPMENT_STATUSES.JOB_UNASSIGNED,
        SHIPMENT_STATUSES.WAITING_FOR_JOB_CONFIRMATION,
        SHIPMENT_STATUSES.JOB_TRANSPORTER_CONFIRMED,
        SHIPMENT_STATUSES.PLANNED,
        SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
        SHIPMENT_STATUSES.UNFULFILLED,
        SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL,
        SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER,
        SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION,
        window.QUEUED_SHIPMENTS && SHIPMENT_STATUSES.QUEUED_AND_BTMS_PLANNED,
      ].filter(Boolean),
    ];
  },
};

export const SHIPMENT_STATUS_LOCAL_GROUPS = {
  [LOCAL_SHIPMENT_STATUSES.UPCOMING]: [SHIPMENT_STATUSES.UPCOMING],
  [LOCAL_SHIPMENT_STATUSES.AT_LOCATION]: [LOCAL_SHIPMENT_STATUSES.AT_LOCATION],
  [LOCAL_SHIPMENT_STATUSES.PLANNED]:
    [
      SHIPMENT_STATUSES.JOB_BIDDING,
      SHIPMENT_STATUSES.JOB_UNASSIGNED,
      SHIPMENT_STATUSES.WAITING_FOR_JOB_CONFIRMATION,
      window.SME_WAITING_FOR_SHIPPER_CONFIRMATION_STATUS && SHIPMENT_STATUSES.JOB_WAITING_FOR_SHIPPER_CONFIRMATION,
      SHIPMENT_STATUSES.JOB_TRANSPORTER_CONFIRMED,
      SHIPMENT_STATUSES.ASSIGNED,
      SHIPMENT_STATUSES.PLANNED,
      window.QUEUED_SHIPMENTS && SHIPMENT_STATUSES.QUEUED_AND_BTMS_PLANNED,
    ].filter(Boolean), // Filter can be removed once the feature flag above is removed.
  [LOCAL_SHIPMENT_STATUSES.AT_LOCATION]: [
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.START_LOADING,
    SHIPMENT_STATUSES.FINISH_LOADING,
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.START_UNLOADING,
    SHIPMENT_STATUSES.FINISH_UNLOADING,
  ],
  [LOCAL_SHIPMENT_STATUSES.HISTORY]: [
    SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
    SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER,
    SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER,
    SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL,
  ],
  [LOCAL_SHIPMENT_STATUSES.AT_WAREHOUSE]: [
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.START_LOADING,
    SHIPMENT_STATUSES.FINISH_LOADING,
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.START_UNLOADING,
    SHIPMENT_STATUSES.FINISH_UNLOADING,
  ],
  [LOCAL_SHIPMENT_STATUSES.IN_TRANSIT]: [
    SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
    SHIPMENT_STATUSES.ON_SHIPMENT,
  ],
  [LOCAL_SHIPMENT_STATUSES.ONGOING]:
    [
      SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
      SHIPMENT_STATUSES.ON_SHIPMENT,
      SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION,
    ],
  [LOCAL_SHIPMENT_STATUSES.COMPLETED]: [
    SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
    SHIPMENT_STATUSES.UNFULFILLED,
    SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL,
    SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER,
  ],
  ...MONITORING_POSTS_TO_STATUS,
  get [LOCAL_SHIPMENT_STATUSES.ALL]() {
    return [
      ...this[LOCAL_SHIPMENT_STATUSES.PLANNED],
      ...this[LOCAL_SHIPMENT_STATUSES.ONGOING],
      ...this[LOCAL_SHIPMENT_STATUSES.AT_LOCATION],
      ...this[LOCAL_SHIPMENT_STATUSES.COMPLETED],
    ];
  },
};

export const SHIPMENT_STATUS_MONITORING_GROUPS = {
  [SHIPMENT_STATUSES.PLANNED]: [
    SHIPMENT_STATUSES.JOB_BIDDING,
    SHIPMENT_STATUSES.JOB_UNASSIGNED,
    SHIPMENT_STATUSES.WAITING_FOR_JOB_CONFIRMATION,
    SHIPMENT_STATUSES.JOB_TRANSPORTER_CONFIRMED,
    SHIPMENT_STATUSES.PLANNED,
    window.SME_WAITING_FOR_SHIPPER_CONFIRMATION_STATUS && SHIPMENT_STATUSES.JOB_WAITING_FOR_SHIPPER_CONFIRMATION,
    window.QUEUED_SHIPMENTS && SHIPMENT_STATUSES.QUEUED_AND_BTMS_PLANNED,
  ],
  [MONITORING_POSTS.beforePickup]: MONITORING_POSTS_TO_STATUS[MONITORING_POSTS.beforePickup],
  [MONITORING_POSTS.pickup]: MONITORING_POSTS_TO_STATUS[MONITORING_POSTS.pickup],
  [MONITORING_POSTS.onShipment]: MONITORING_POSTS_TO_STATUS[MONITORING_POSTS.onShipment],
  [MONITORING_POSTS.atDestination]: MONITORING_POSTS_TO_STATUS[MONITORING_POSTS.atDestination],
  [MONITORING_POSTS.podUpload]: [SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION],
  [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: [
    SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
    SHIPMENT_STATUSES.UNFULFILLED,
    SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL,
    SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER,
  ],
};

export const TRUCKING_SHIPMENT_STATUS_MONITORING_GROUPS = {
  [SHIPMENT_STATUSES.PLANNED]: [
    SHIPMENT_STATUSES.JOB_UNASSIGNED,
    SHIPMENT_STATUSES.PLANNED,
    SHIPMENT_STATUSES.QUEUED_AND_BTMS_PLANNED,
    SHIPMENT_STATUSES.JOB_BIDDING,
    SHIPMENT_STATUSES.JOB_TRANSPORTER_CONFIRMED,
  ],
  [MONITORING_POSTS.beforePickup]: MONITORING_POSTS_TO_STATUS[MONITORING_POSTS.beforePickup],
  [MONITORING_POSTS.pickup]: MONITORING_POSTS_TO_STATUS[MONITORING_POSTS.pickup],
  [MONITORING_POSTS.onShipment]: MONITORING_POSTS_TO_STATUS[MONITORING_POSTS.onShipment],
  [MONITORING_POSTS.atDestination]: MONITORING_POSTS_TO_STATUS[MONITORING_POSTS.atDestination],
  [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: [
    SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
    SHIPMENT_STATUSES.UNFULFILLED,
    SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER,
  ],
};

export const TRUCKING_SHIPMENT_STATUS_GROUP_MAPPING = {
  [SHIPMENT_STATUSES.JOB_UNASSIGNED]: SHIPMENT_STATUSES.PLANNED,
  [SHIPMENT_STATUSES.PLANNED]: SHIPMENT_STATUSES.PLANNED,
  [SHIPMENT_STATUSES.QUEUED_AND_BTMS_PLANNED]: SHIPMENT_STATUSES.PLANNED,
  [SHIPMENT_STATUSES.JOB_BIDDING]: SHIPMENT_STATUSES.PLANNED,
  [SHIPMENT_STATUSES.JOB_TRANSPORTER_CONFIRMED]: SHIPMENT_STATUSES.PLANNED,
  [SHIPMENT_STATUSES.ASSIGNED]: MONITORING_POSTS.beforePickup,
  [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: MONITORING_POSTS.beforePickup,
  [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: MONITORING_POSTS.pickup,
  [SHIPMENT_STATUSES.START_LOADING]: MONITORING_POSTS.pickup,
  [SHIPMENT_STATUSES.FINISH_LOADING]: MONITORING_POSTS.pickup,
  [SHIPMENT_STATUSES.ON_SHIPMENT]: MONITORING_POSTS.onShipment,
  [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: MONITORING_POSTS.atDestination,
  [SHIPMENT_STATUSES.START_UNLOADING]: MONITORING_POSTS.atDestination,
  [SHIPMENT_STATUSES.FINISH_UNLOADING]: MONITORING_POSTS.atDestination,
  [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
  [SHIPMENT_STATUSES.UNFULFILLED]: SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
  [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
};

export const SHIPMENT_COUNT_QUERY_NAME = {
  [SHIPMENT_STATUSES.UPCOMING]: 'upcomingCount',
  [SHIPMENT_STATUSES.PLANNED]: 'plannedCount',
  [SHIPMENT_STATUSES.ASSIGNED]: 'assignedCount',
  [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'arriveAtPickupCount',
  [SHIPMENT_STATUSES.START_LOADING]: 'startLoadingCount',
  [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'arriveAtDestinationCount',
  [SHIPMENT_STATUSES.START_UNLOADING]: 'startUnloadingCount',
  [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'finishUnloadingCount',
  [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'shipmentCompleteCount',
  [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'cancelledByTransporterCount',
  [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'cancelledByShipperCount',
  [SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL]: 'cancelledByInternalCount',
  [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME]: 'queueingOvertimeCount',
  [DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE]: 'behindTheScheduleCount',
  [DERIVED_SHIPMENT_STATUSES.ON_TIME]: 'onTimeCount',
  [SHIPMENT_STATUSES.LATE_TO_ORIGIN]: 'atOriginLateCount',
  [SHIPMENT_STATUSES.LATE_TO_DESTINATION]: 'atDestinationLateCount',
  [DERIVED_SHIPMENT_STATUSES.QUEUE_AT_ORIGIN_LATE]:
    'queueAtOriginLateCount',
  [DERIVED_SHIPMENT_STATUSES.QUEUE_AT_DESTINATION_LATE]:
    'queueAtDestinationLateCount',
  [SHIPMENT_STATUSES.JOB_BIDDING]: 'biddingCount',
  [SHIPMENT_STATUSES.JOB_TRANSPORTER_CONFIRMED]:
    'transporterConfirmedCount',
};

export const SHIPMENT_COUNT_FILTER_QUERY_NAME = {
  [DERIVED_SHIPMENT_STATUSES.QUEUE_AT_ORIGIN_LATE]: 'queueAtOriginLate',
  [DERIVED_SHIPMENT_STATUSES.QUEUE_AT_DESTINATION_LATE]:
    'queueAtDestinationLate',
};

export const SHIPMENT_STATUS_COUNTS_LOCAL_GROUPS = {
  [LOCAL_SHIPMENT_STATUSES.PLANNED]: [
    SHIPMENT_STATUSES.JOB_BIDDING,
    SHIPMENT_STATUSES.JOB_TRANSPORTER_CONFIRMED,
  ].filter(Boolean),
  [LOCAL_SHIPMENT_STATUSES.AT_WAREHOUSE]: [
    DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME,
    SHIPMENT_STATUSES.START_LOADING,
  ],
  [LOCAL_SHIPMENT_STATUSES.AT_LOCATION]: [
    DERIVED_SHIPMENT_STATUSES.QUEUE_AT_ORIGIN_LATE,
    DERIVED_SHIPMENT_STATUSES.QUEUE_AT_DESTINATION_LATE,
  ],
  [LOCAL_SHIPMENT_STATUSES.IN_TRANSIT]: [
    DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE,
    DERIVED_SHIPMENT_STATUSES.ON_TIME,
  ],
  [LOCAL_SHIPMENT_STATUSES.ONGOING]: [
    SHIPMENT_STATUSES.LATE_TO_ORIGIN,
    SHIPMENT_STATUSES.LATE_TO_DESTINATION,
  ].filter(Boolean),
  [LOCAL_SHIPMENT_STATUSES.COMPLETED]: [
    SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL,
    SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
  ].filter(Boolean),
};

export const PAYMENT_REQUEST_AMOUNT_STATUS = {
  DISBURSED_AMOUNT: 'DISBURSED_AMOUNT',
  PENDING_AMOUNT: 'PENDING_AMOUNT',
  PENDING_AMOUNT_AUTOMATED: 'PENDING_AMOUNT_AUTOMATED',
};

export const VAT_PERCENTAGE_OF_PAYMENT_REQUEST_REVIEW = [
  { label: '0%', value: 0.0 },
  { label: '1%', value: 0.01 },
  { label: '10%', value: 0.1 },
];

export const WHAT23_PERCENTAGE_OF_PAYMENT_REQUEST_REVIEW = [
  { label: '0%', value: 0.0 },
  { label: '2%', value: 0.02 },
  { label: '4%', value: 0.04 },
  { label: '6%', value: 0.06 },
];

export const WHT23_PERCENTAGES = [
  { label: '0%', value: 0.0 },
  { label: '2%', value: 0.02 },
  { label: '4%', value: 0.04 },
];

export const WHT21_PERCENTAGES = [
  { label: '0%', value: 0.0 },
  { label: '2.5%', value: 0.025 },
  { label: '3%', value: 0.03 },
];

export const TAX_TYPES_OF_PAYMENT_REQUEST = {
  VAT: 'VAT',
  WHT15: 'WHT15',
  WHT21: 'WHT21',
  WHT23: 'WHT23',
};

export const READINESS_SHIPMENT_STATUS = {
  READY: 'READY',
  ABOUT_TO_BE_READY: 'ABOUT_TO_BE_READY',
};

export const READINESS_SHIPMENT_STATUS_COLORS = {
  [READINESS_SHIPMENT_STATUS.READY]: 'success',
  [READINESS_SHIPMENT_STATUS.ABOUT_TO_BE_READY]: 'orangeyYellow',
};

export const ARMADA_SHIPMENT_STATUSES = [
  SHIPMENT_STATUSES.ASSIGNED,
  SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
  SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
  SHIPMENT_STATUSES.START_LOADING,
  SHIPMENT_STATUSES.FINISH_LOADING,
  SHIPMENT_STATUSES.ON_SHIPMENT,
  SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
  SHIPMENT_STATUSES.START_UNLOADING,
  SHIPMENT_STATUSES.FINISH_UNLOADING,
  SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
];

export const GENERAL_ENTITY_STATUSES = {
  REGISTERED: 'REGISTERED',
  DEACTIVATED: 'DEACTIVATED',
};

export const COMPANY_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  REGISTERED: 'REGISTERED',
  CHURN: 'CHURN',
  DEACTIVATED: 'DEACTIVATED',
};

export const LOCATION_STATUSES = {
  REGISTERED: 'REGISTERED',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const COMPANY_STATUS_COLORS = {
  [COMPANY_STATUSES.ACTIVE]: 'success',
  [COMPANY_STATUSES.INACTIVE]: 'danger',
  [COMPANY_STATUSES.REGISTERED]: 'orangeyYellow',
  [COMPANY_STATUSES.CHURN]: 'danger',
  [COMPANY_STATUSES.DEACTIVATED]: 'danger',
};

export const LOCATION_STATUS_COLORS = {
  [LOCATION_STATUSES.REGISTERED]: 'success',
  [LOCATION_STATUSES.ACTIVE]: 'success',
  [LOCATION_STATUSES.INACTIVE]: 'danger',
};

export const GENERAL_ENTITY_STATUS_COLORS = {
  REGISTERED: 'success',
  DEACTIVATED: 'danger',
};

export const BROKERAGE_EXTRA_USER_ACCESS_TYPE = {
  ACCOUNT_PAYABLE: 'ACCOUNT_PAYABLE',
  AR: 'AR',
  DOCUMENT_COLLECTION: 'DOCUMENT_COLLECTION',
  FINANCE_ADMIN: 'FINANCE_ADMIN',
  FINANCE: 'FINANCE',
  KARGO_SHIPPER_SALES: 'KARGO_SHIPPER_SALES',
  SALES_SUPPORT: 'SALES_SUPPORT',
  SALES: 'SALES',
  SME_SALES_ADMIN: 'SME_SALES_ADMIN',
  SME_SALES_LEAD: 'SME_SALES_LEAD',
  TRAFFIC_CONTROLLER: 'TRAFFIC_CONTROLLER',
  TRANSPORT_PLANNER: 'TRANSPORT_PLANNER',
  TRANSPORTER_PRICING_MANAGER: 'TRANSPORTER_PRICING_MANAGER',
  TRANSPORTER_PRICING_VIEWER: 'TRANSPORTER_PRICING_VIEWER',
  VENDOR_MANAGEMENT: 'VENDOR_MANAGEMENT',
  LEGAL: 'LEGAL',
};

export const PLATFORM_ACCESS_TYPE = {
  LOGISTIC_PIC: 'LOGISTIC_PIC',
  MONITORING_PIC: 'MONITORING_PIC',
  KARGO_SHIPPER_SALES: 'KARGO_SHIPPER_SALES',
  SHIPPER_PLATFORM: 'SHIPPER_PLATFORM',
};

/**
 * ADMIN access for the whole TMS
 * FULL_ACCESS & RESTRICTED_ACCESS are registered for TTMS
 * SALES, SALES_SUPPORT, AR, FINANCE, VENDOR_MANAGEMENT, ACCOUNT_PAYABLE are for BTMS
 */

export const USER_ACCESS_TYPE = {
  ADMIN: 'ADMIN',
  FULL_ACCESS: 'FULL_ACCESS',
  RESTRICTED_ACCESS: 'RESTRICTED_ACCESS',
  RISK: 'RISK',
  ...BROKERAGE_EXTRA_USER_ACCESS_TYPE,
};

export const FILE_UPLOAD_SOURCE = {
  BTMS: 'BTMS',
  DA: 'DA',
  CA: 'CA',
  TMS: 'TMS',
};

export const FILE_PREVIEW_TYPE = {
  IMAGE: 'IMAGE',
  GDRIVE_FOLDER: 'GDRIVE_FOLDER',
  GDRIVE_FILE: 'GDRIVE_FILE',
  PDF: 'PDF',
};

export const MIME_TO_FILE_PREVIEW_TYPE_MAP = {
  'image/jpeg': FILE_PREVIEW_TYPE.IMAGE,
  'image/png': FILE_PREVIEW_TYPE.IMAGE,
  'application/pdf': FILE_PREVIEW_TYPE.PDF,
};

export const INVOICE_STATUS = {
  NOT_INVOICED_YET: null,
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAID: 'PAID',
  INVOICE_GENERATED: 'INVOICE_GENERATED',
};

export const INVOICABLE_DO_STATUS = {
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  NOT_INVOICED_YET: null,
};

export const CUSTOMIZABLE_INVOICE_STATUSES = {
  NOT_INVOICED: 'NOT_INVOICED',
  PARTIALLY_INVOICED: 'PARTIALLY_INVOICED',
};

export const INVOICE_PAYMENT_STATUS = {
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAID: 'PAID',
};

export const BROKERAGE_INVOICE_STATUS = {
  NOT_INVOICEABLE: 'NOT_INVOICEABLE',
  NOT_INVOICED: 'NOT_INVOICED',
  INVOICED: 'INVOICED',
};

export const INVOICE_STATUS_SEQUENCE = [
  INVOICE_STATUS.NOT_INVOICED_YET,
  INVOICE_STATUS.PAYMENT_PENDING,
  INVOICE_STATUS.PAID,
];

export const BROKERAGE_INVOICE_STATUS_SEQUENCE = [
  BROKERAGE_INVOICE_STATUS.NOT_INVOICED,
  BROKERAGE_INVOICE_STATUS.INVOICED,
];

export const INVOICE_STATUS_COLOR = {
  [INVOICE_STATUS.NOT_INVOICED_YET]: 'darkGrey',
  [INVOICE_STATUS.PAYMENT_PENDING]: 'orangeyYellow',
  [INVOICE_STATUS.PAID]: 'success',
};

export const BROKERAGE_INVOICE_STATUS_COLOR = {
  [BROKERAGE_INVOICE_STATUS.NOT_INVOICED]: 'darkGrey',
  [BROKERAGE_INVOICE_STATUS.INVOICED]: 'orangeyYellow',
  [BROKERAGE_INVOICE_STATUS.BAD_DEBT]: 'danger',
  [BROKERAGE_INVOICE_STATUS.NOT_INVOICEABLE]: 'danger',
};

export const DISPLAY_MODE = {
  READ: 'READ',
  WRITE: 'WRITE',
  PRINT: 'PRINT',
};

export const DOF_SHIPMENT_STATUSES = {
  POL_PENDING: 'POL_PENDING',
  POL_ACCEPTED: 'POL_ACCEPTED',
  POD_ACCEPTED: 'POD_ACCEPTED',
  POD_REJECTED: 'POD_REJECTED',
  POL_REJECTED: 'POL_REJECTED',
};

export const DOF_SHIPMENT_STATUS_COLORS = {
  [DOF_SHIPMENT_STATUSES.POL_PENDING]: 'darkGrey',
  [DOF_SHIPMENT_STATUSES.POL_ACCEPTED]: 'primary',
  [DOF_SHIPMENT_STATUSES.POD_ACCEPTED]: 'primary',
  [DOF_SHIPMENT_STATUSES.POD_REJECTED]: 'warning',
  [DOF_SHIPMENT_STATUSES.POL_REJECTED]: 'warning',
};

export const SWIFT_PAYMENT_REQUEST_STATUSES = {
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  DISBURSED: 'DISBURSED',
  ON_HOLD: 'ON_HOLD',
  DOF2_PROCESSED_PARTIALLY: 'DOF2_PROCESSED_PARTIALLY',
  SECOND_DISBURSEMENT_IN_PROCESS: 'SECOND_DISBURSEMENT_IN_PROCESS',
  SECOND_DISBURSEMENT_DISBURSED: 'SECOND_DISBURSEMENT_DISBURSED',
  THIRD_DISBURSEMENT_IN_PROCESS: 'THIRD_DISBURSEMENT_IN_PROCESS',
  THIRD_DISBURSEMENT_DISBURSED: 'THIRD_DISBURSEMENT_DISBURSED',
};

export const SWIFT_PAYMENT_REQUEST_STATUS_COLORS = {
  [SWIFT_PAYMENT_REQUEST_STATUSES.REQUESTED]: 'warning',
  [SWIFT_PAYMENT_REQUEST_STATUSES.APPROVED]: 'primary',
  [SWIFT_PAYMENT_REQUEST_STATUSES.REJECTED]: 'danger',
  [SWIFT_PAYMENT_REQUEST_STATUSES.DISBURSED]: 'success',
  [SWIFT_PAYMENT_REQUEST_STATUSES.ON_HOLD]: 'warning',
  [SWIFT_PAYMENT_REQUEST_STATUSES.DOF2_PROCESSED_PARTIALLY]: 'warning',
  [SWIFT_PAYMENT_REQUEST_STATUSES.SECOND_DISBURSEMENT_IN_PROCESS]: 'primary',
  [SWIFT_PAYMENT_REQUEST_STATUSES.SECOND_DISBURSEMENT_DISBURSED]: 'success',
  [SWIFT_PAYMENT_REQUEST_STATUSES.THIRD_DISBURSEMENT_IN_PROCESS]: 'primary',
  [SWIFT_PAYMENT_REQUEST_STATUSES.THIRD_DISBURSEMENT_DISBURSED]: 'success',
};

export const SWIFT_PAYMENT_DOCUMENT_STATUSES = {
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const DOF2_REQUEST_ON_HOLD_REASON = {
  ALL_DOCUMENTS_REJECTED: 'ALL_DOCUMENTS_REJECTED',
  CREDIT_LIMIT_EXCEEDED: 'CREDIT_LIMIT_EXCEEDED',
  LATE_PAYMENT_ISSUE: 'LATE_PAYMENT_ISSUE',
  OTHER: 'OTHER',
};

export const DOF_REQUEST_ON_HOLD_REASON = {
  ALL_DOCUMENTS_REJECTED: 'ALL_DOCUMENTS_REJECTED',
  CREDIT_LIMIT_EXCEEDED: 'CREDIT_LIMIT_EXCEEDED',
  LATE_PAYMENT_ISSUE: 'LATE_PAYMENT_ISSUE',
  OTHER: 'OTHER',
};

export const SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE = {
  CLAIM: 'CLAIM',
  TRANSFER_FEE: 'TRANSFER_FEE',
  ADDITION_IN_DO_PRICE: 'SHIPMENT_FEE_ADDITION',
  REDUCE_IN_DO_PRICE: 'SHIPMENT_FEE_DEDUCTION',
  OTHERS: 'OTHERS',
};

export const PODF_REQUEST_ON_HOLD_REASON = {
  ALL_DOCUMENTS_REJECTED: 'ALL_DOCUMENTS_REJECTED',
  CREDIT_LIMIT_EXCEEDED: 'CREDIT_LIMIT_EXCEEDED',
  LATE_PAYMENT_ISSUE: 'LATE_PAYMENT_ISSUE',
  OTHER: 'OTHER',
};

export const DOF2_REQUEST_ON_HOLD_REASON_OPTIONS = [
  DOF2_REQUEST_ON_HOLD_REASON.CREDIT_LIMIT_EXCEEDED,
  DOF2_REQUEST_ON_HOLD_REASON.LATE_PAYMENT_ISSUE,
  DOF2_REQUEST_ON_HOLD_REASON.OTHER,
];

export const DOF_REQUEST_REJECTION_REASON = {
  POL_BLUR: 'POL_BLUR',
  POD_BLUR: 'POD_BLUR',
  POL_SHIPMENT_NUMBER_MISMATCH: 'POL_SHIPMENT_NUMBER_MISMATCH',
  POD_SHIPMENT_NUMBER_MISMATCH: 'POD_SHIPMENT_NUMBER_MISMATCH',
  OTHER: 'OTHER',
};

export const DOF_REQUEST_REJECTION_REASON_GROUP = {
  POL: [
    DOF_REQUEST_REJECTION_REASON.POL_BLUR,
    DOF_REQUEST_REJECTION_REASON.POL_SHIPMENT_NUMBER_MISMATCH,
    DOF_REQUEST_REJECTION_REASON.OTHER,
  ],
  POD: [
    DOF_REQUEST_REJECTION_REASON.POD_BLUR,
    DOF_REQUEST_REJECTION_REASON.POD_SHIPMENT_NUMBER_MISMATCH,
    DOF_REQUEST_REJECTION_REASON.OTHER,
  ],
};

export const PODF_INVOICE_REJECTION_REASON = {
  INVOICE_BLUR: 'INVOICE_BLUR',
  INVOICE_NUMBER_MISMATCH: 'INVOICE_NUMBER_MISMATCH',
  OTHER: 'OTHER',
};

export const PODF_INVOICE_RECEIPT_REJECTION_REASON = {
  INVOICE_RECEIPT_BLUR: 'INVOICE_BLUR',
  INVOICE_NUMBER_MISMATCH: 'INVOICE_NUMBER_MISMATCH',
  OTHER: 'OTHER',
};

export const DOF2_INVOICE_REJECTION_REASON = {
  INVOICE_BLUR: 'INVOICE_BLUR',
  INVOICE_NUMBER_MISMATCH: 'INVOICE_NUMBER_MISMATCH',
  OTHER: 'OTHER',
};

export const DOF2_INVOICE_RECEIPT_REJECTION_REASON = {
  INVOICE_RECEIPT_BLUR: 'INVOICE_BLUR',
  INVOICE_NUMBER_MISMATCH: 'INVOICE_NUMBER_MISMATCH',
  OTHER: 'OTHER',
};

export const SWIFT_PAYMENT_TYPES = {
  DOF: 'DOF',
  PODF: 'PODF',
  DOF2: 'DOF-2',
};

export const PODF_FIRST_DISBURSEMENT_PERCENTAGE = 80;
export const PODF_SECOND_DISBURSEMENT_PERCENTAGE = 30;
export const PODF_ADDITIONAL_FEES_SECOND_DISBURSEMENT_PERCENTAGE = 80;
export const DOF_THIRD_DISBURSEMENT_PERCENTAGE = 20;
export const DOF2_ADDITIONAL_FEE = 80;

export const DOF2_ELIGIBILITY_STATUS = {
  ELIGIBLE: 0,
  SHIPMENT_NOT_COMPLETED: 1,
  DO_IS_ALREADY_IN_ANOTHER_DOF2_REQUEST: 2,
  DO_IS_ALREADY_IN_ANOTHER_INVOICE: 3,
  DOF_NOT_DISBURSED_YET: 4,
  UNDEFINED_BACKEND_CODE: 5,
  DOF_REQUEST_NOT_FOUND: 6,
};

export const INVOICE_DOF2_ELIGIBILITY_STATUS = {
  ELIGIBLE: 0,
  INVOICE_NOT_IN_PAYMENT_PENDING: 1,
  ALL_DO_ARE_NOT_PART_OF_DOF_REQUEST: 2,
  INVOICE_IS_PART_OF_OTHER_DOF2: 3,
};

export const DOF_FIRST_DISBURSEMENT_PERCENTAGE = 50;
export const DOF_SECOND_DISBURSEMENT_PERCENTAGE = 30;
export const DOF_ADDITIONAL_FEES_SECOND_DISBURSEMENT_PERCENTAGE = 80;
export const DOF_THIRD_INVOICE_PPH_CLAIM_TRANSFER_FEE_ADJUSTMENT_FEE_PERCENTAGE = 20;

// [Start] Fist Mile Project Specific
export const FIRST_MILE_USER_ACCESS_TYPE = {
  FREIGHT_FORWARDER: 'FREIGHT_FORWARDER',
  CONSIGNEE: 'CONSIGNEE',
};

export const CONTAINER_FORM_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  BATCH_UPDATE: 'BATCH_UPDATE',
};

export const CONTAINER_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY',
  BATCH_UPDATE_FORM: 'BATCH_UPDATE_FORM',
};

export const CONTAINER_STATUSES = {
  ARRIVED_AT_PORT: 'ARRIVED_AT_PORT',
  PIB: 'PIB',
  NO_NOA: 'NO_NOA',
  NOA: 'NOA',
  SPPB: 'SPPB',
  WAITING_FOR_SPPB: 'WAITING_FOR_SPPB',
  WAITING_FOR_ORIGINAL_DOC: 'WAITING_FOR_ORIGINAL_DOC',
  QUARANTINED: 'QUARANTINED',
  ON_SHIPMENT: 'ON_SHIPMENT',
  MOVED_TO_HOLDING: 'MOVED_TO_HOLDING',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  INVOICED: 'INVOICED',
  PAID: 'PAID',
  // Used in current BTMS First-Mile
  UPCOMING: 'UPCOMING',
  UNASSIGNED: 'UNASSIGNED',
  BIDDING: 'BIDDING',
  TRANSPORTER_CONFIRMED: 'TRANSPORTER_CONFIRMED',
  READY_TO_BOOK: 'READY_TO_BOOK',
  VESSEL_BOOKED: 'VESSEL_BOOKED',
  ASSIGNED: 'ASSIGNED',
  ONGOING_PORT_OF_LOADING: 'ONGOING_PORT_OF_LOADING',
  AT_PORT_OF_LOADING: 'AT_PORT_OF_LOADING',
  ONGOING_PORT_OF_DISCHARGE: 'ONGOING_PORT_OF_DISCHARGE',
  AT_PORT_OF_DISCHARGE: 'AT_PORT_OF_DISCHARGE',
  ONGOING_DESTINATION_WAREHOUSE: 'ONGOING_DESTINATION_WAREHOUSE',
  AT_DESTINATION_WAREHOUSE: 'AT_DESTINATION_WAREHOUSE',
  SHIPMENT_COMPLETE: 'SHIPMENT_COMPLETE',
  // Terminal statuses
  UNFULFILLED: 'UNFULFILLED',
  REASSIGNED_BY_SHIPPER: 'REASSIGNED_BY_SHIPPER',
  REASSIGNED_BY_BROKERAGE: 'REASSIGNED_BY_BROKERAGE',
  CONFIRMATION_EXPIRED: 'CONFIRMATION_EXPIRED',
  REJECTED_BY_TRANSPORTER: 'REJECTED_BY_TRANSPORTER',
  CANCELLED_BY_TRANSPORTER: 'CANCELLED_BY_TRANSPORTER',
  CANCELLED_BY_SHIPPER: 'CANCELLED_BY_SHIPPER',
  CANCELLED_BY_INTERNAL: 'CANCELLED_BY_INTERNAL',
};

export const CONTAINER_STATUS_COLOR = {
  [CONTAINER_STATUSES.ARRIVED_AT_PORT]: 'primary',
  [CONTAINER_STATUSES.ON_SHIPMENT]: 'primary',
  [CONTAINER_STATUSES.SHIPMENT_COMPLETE]: 'success',
  [CONTAINER_STATUSES.PAYMENT_PENDING]: 'warning',
  [CONTAINER_STATUSES.WAITING_FOR_SPPB]: 'warning',
  [CONTAINER_STATUSES.WAITING_FOR_ORIGINAL_DOC]: 'warning',
  [CONTAINER_STATUSES.PAID]: 'success',
  [CONTAINER_STATUSES.MOVED_TO_HOLDING]: 'primary',
  [CONTAINER_STATUSES.PIB]: 'primary',
  [CONTAINER_STATUSES.NO_NOA]: 'warning',
  [CONTAINER_STATUSES.NOA]: 'primary',
  [CONTAINER_STATUSES.SPPB]: 'primary',
  [CONTAINER_STATUSES.QUARANTINED]: 'primary',
  [CONTAINER_STATUSES.INVOICED]: 'success',
  // Used in current BTMS First-Mile
  [CONTAINER_STATUSES.UPCOMING]: 'danger',
  [CONTAINER_STATUSES.UNASSIGNED]: 'orangeYellow',
  [CONTAINER_STATUSES.BIDDING]: 'orangeYellow',
  [CONTAINER_STATUSES.TRANSPORTER_CONFIRMED]: 'deepSeaBlue',
  [CONTAINER_STATUSES.READY_TO_BOOK]: 'primary',
  [CONTAINER_STATUSES.VESSEL_BOOKED]: 'steelBlue',
  [CONTAINER_STATUSES.ASSIGNED]: 'primary',
  [CONTAINER_STATUSES.ONGOING_PORT_OF_LOADING]: 'primary',
  [CONTAINER_STATUSES.AT_PORT_OF_LOADING]: 'success',
  [CONTAINER_STATUSES.ONGOING_PORT_OF_DISCHARGE]: 'primary',
  [CONTAINER_STATUSES.AT_PORT_OF_DISCHARGE]: 'success',
  [CONTAINER_STATUSES.ONGOING_DESTINATION_WAREHOUSE]: 'primary',
  [CONTAINER_STATUSES.AT_DESTINATION_WAREHOUSE]: 'success',
  [CONTAINER_STATUSES.SHIPMENT_COMPLETE]: 'grey',
  // Terminal statuses
  [CONTAINER_STATUSES.UNFULFILLED]: 'danger',
  [CONTAINER_STATUSES.REASSIGNED_BY_SHIPPER]: 'danger',
  [CONTAINER_STATUSES.REASSIGNED_BY_BROKERAGE]: 'danger',
  [CONTAINER_STATUSES.CONFIRMATION_EXPIRED]: 'danger',
  [CONTAINER_STATUSES.REJECTED_BY_TRANSPORTER]: 'danger',
  [CONTAINER_STATUSES.CANCELLED_BY_TRANSPORTER]: 'danger',
  [CONTAINER_STATUSES.CANCELLED_BY_SHIPPER]: 'danger',
  [CONTAINER_STATUSES.CANCELLED_BY_INTERNAL]: 'danger',
};

export const FIRST_MILE_INVOICE_FORM_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
};

export const FIRST_MILE_INVOICE_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY',
};

export const FIRST_MILE_INVOICE_STATUSES = {
  DUE: 'DUE',
  OVERDUE: 'OVERDUE',
  VERY_OVERDUE: 'VERY_OVERDUE',
  PAID: 'PAID',
};

export const FIRST_MILE_INVOICE_STATUS_COLORS = {
  [FIRST_MILE_INVOICE_STATUSES.DUE]: 'primary',
  [FIRST_MILE_INVOICE_STATUSES.OVERDUE]: 'warning',
  [FIRST_MILE_INVOICE_STATUSES.VERY_OVERDUE]: 'danger',
  [FIRST_MILE_INVOICE_STATUSES.PAID]: 'success',
};

export const BILL_OF_LADING_FORM_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
};

export const BILL_OF_LADING_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY',
};

export const FIRST_MILE_DOCUMENT_TYPES = {
  HOUSE_BILL_OF_LADING: 'HOUSE_BILL_OF_LADING',
  BILL_OF_LADING: 'BILL_OF_LADING',
  NOTICE_OF_ARRIVAL: 'NOTICE_OF_ARRIVAL',
  PIB: 'PIB',
  QUARANTINE: 'QUARANTINE',
  EBILLING: 'EBILLING',
  SHIPPING_LINE_DELIVERY_ORDER: 'SHIPPING_LINE_DELIVERY_ORDER',
  STORAGE_CHARGES: 'STORAGE_CHARGES',
  TILA: 'TILA',
  SPPB: 'SPPB',
  CONTAINER_RENTAL: 'CONTAINER_RENTAL',
  PACKING_LIST: 'PACKING_LIST',
  MATERIAL_SAFETY_AND_DATA_SHEET: 'MATERIAL_SAFETY_AND_DATA_SHEET',
  CERTIFICATE_OF_ORIGIN: 'CERTIFICATE_OF_ORIGIN',
  PROOF_OF_DELIVERY: 'PROOF_OF_DELIVERY',
  LOLO: 'LOLO',
  TAX_INVOICE: 'TAX_INVOICE',
  BILL_OF_LADING_INVOICE: 'BILL_OF_LADING_INVOICE',
};

export const CONTAINER_TYPES = {
  REEFER: 'REEFER',
  DRY: 'DRY',
};

export const FIRST_MILE_CONTAINER_SIZES = {
  '20_FEET': '20_FEET',
  '20_FR': '20_FR',
  '20_GP': '20_GP',
  '20_OT': '20_OT',
  '20_OW': '20_OW',
  '40_FEET': '40_FEET',
  '40_FR': '40_FR',
  '40_GP': '40_GP',
  '40_HC': '40_HC',
  '40_OW': '40_OW',
  '40_OT': '40_OT',
  '45_FEET': '45_FEET',
};

export const FIRST_MILE_CONTAINER_GRADE = {
  FOOD_GRADE: 'Food Grade',
  GRADE_A: 'A',
  GRADE_B: 'B',
  GRADE_C: 'C',
};

export const FIRST_MILE_COMPANY_CONSTANT_TYPES = {
  PORT: 'PORT',
  SUPPLIER: 'SUPPLIER',
  SHIPPING_LINE: 'SHIPPING_LINE',
  CONSIGNEE: 'CONSIGNEE',
  NOTIFY_PARTY: 'NOTIFY_PARTY',
  TERMINAL: 'TERMINAL',
};

export const FIRST_MILE_COMMODITIES = {
  'Memphis Raw Cotton': 'Memphis Raw Cotton',
  'American Raw Cotton': 'American Raw Cotton',
  'Brazillian Raw Cotton': 'Brazillian Raw Cotton',
  Machinery: 'Machinery',
  Chemicals: 'Chemicals',
  'Polyester Staple Fibre': 'Polyester Staple Fibre',
};

export const CUSTOMS_TYPES = {
  BC20: 'BC20',
  BC23: 'BC23',
  BC25: 'BC25',
};

export const DISBURSEMENT_SCHEME_TYPE = {
  BROKERAGE: 'BROKERAGE',
  MARKETPLACE: 'MARKETPLACE',
  FINANCING: 'FINANCING',
};

export const CONTRACT_SCHEME_TYPE = {
  ALL_SHIPPER: 'ALL_SHIPPER',
  SINGLE_SHIPPER: 'SINGLE_SHIPPER',
};

export const CONTRACT_TYPE = {
  MID_MILE: 'MID_MILE',
  TRUCKING_DTD: 'TRUCKING_DTD',
  DOORING_DTD: 'DOORING_DTD',
};

export const TRANSPORTER_PARTNERSHIP_CONTRACT = {
  MID_MILE: 'MID_MILE',
  FIRST_MILE_TRUCKING: 'FIRST_MILE_TRUCKING',
  DOORING_DTD: 'DOORING_DTD',
};

export const DISBURSEMENT_SCHEME_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const DISBURSEMENT_SCHEME_STATUS_COLOR = {
  [DISBURSEMENT_SCHEME_STATUS.ACTIVE]: 'success',
  [DISBURSEMENT_SCHEME_STATUS.INACTIVE]: 'danger',
};

export const DISBURSEMENT_SCHEME_PAYMENT_STAGES = {
  EMONEY_STAGE: 'EMONEY_STAGE',
  DO_STAGE: 'DO_STAGE',
  POL_STAGE: 'POL_STAGE',
  POD_DIGITAL_STAGE: 'POD_DIGITAL_STAGE',
  POD_PHYSICAL_STAGE: 'POD_PHYSICAL_STAGE',
  INVOICE_SENT_STAGE: 'INVOICE_SENT_STAGE',
  INVOICE_PAID_STAGE: 'INVOICE_PAID_STAGE',
};

// [End] Fist Mile Project Specific

export const EMPLOYEE_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY',
};

export const SHIPMENT_COMPANY_CONSTANT_TYPES = {
  SHIPMENT_TYPE: 'SHIPMENT_TYPE',
  ORDER_TYPE: 'ORDER_TYPE',
};

export const SHIPMENT_TYPE_OPTIONS = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTI',
  PODF: 'PODF',
};

export const APP_CONTEXT_STORAGE_KEY = 'appContext';

export const FEE_REQUEST_OVERALL_STATUS = {
  REVIEW_SHIPMENT_FEE: 'REVIEW_SHIPMENT_FEE',
  REVIEW_ADDITIONAL_FEE: 'REVIEW_ADDITIONAL_FEE',
  ON_HOLD: 'ON_HOLD',
  REVIEWED: 'REVIEWED',
  FEE_REJECTED: 'FEE_REJECTED',
  DISBURSED: 'DISBURSED',
  REQUEST_SENT: 'REQUEST_SENT',
  REQUEST_APPROVED: 'REQUEST_APPROVED',
  WAITING_FOR_CLAIM: 'WAITING_FOR_CLAIM',
  WAITING_FOR_PENALTY: 'WAITING_FOR_PENALTY',
  WAITING_FOR_OVERNIGHT: 'WAITING_FOR_OVERNIGHT',
  WAITING_FOR_TKBM: 'WAITING_FOR_TKBM',
  WAITING_FOR_INVOICE: 'WAITING_FOR_INVOICE',
  WAITING_FOR_INVOICE_REVISION: 'WAITING_FOR_INVOICE_REVISION',
  PARTIALLY_DISBURSED: 'PARTIALLY_DISBURSED',
};

export const FEE_REQUEST_HOLD_STATUSES = [
  FEE_REQUEST_OVERALL_STATUS.ON_HOLD,
  FEE_REQUEST_OVERALL_STATUS.WAITING_FOR_CLAIM,
  FEE_REQUEST_OVERALL_STATUS.WAITING_FOR_PENALTY,
  FEE_REQUEST_OVERALL_STATUS.WAITING_FOR_OVERNIGHT,
  FEE_REQUEST_OVERALL_STATUS.WAITING_FOR_TKBM,
  FEE_REQUEST_OVERALL_STATUS.WAITING_FOR_INVOICE,
  FEE_REQUEST_OVERALL_STATUS.WAITING_FOR_INVOICE_REVISION,
];

export const FEE_REQUEST_OVERALL_STATUS_NEED_REVIEW = [
  FEE_REQUEST_OVERALL_STATUS.REVIEW_SHIPMENT_FEE,
  FEE_REQUEST_OVERALL_STATUS.REVIEW_ADDITIONAL_FEE,
  ...FEE_REQUEST_HOLD_STATUSES,
];

export const FEE_REQUEST_OVERALL_STATUS_COLOR = {
  [FEE_REQUEST_OVERALL_STATUS.REVIEW_SHIPMENT_FEE]: 'orangeyYellow',
  [FEE_REQUEST_OVERALL_STATUS.REVIEW_ADDITIONAL_FEE]: 'orangeyYellow',
  [FEE_REQUEST_OVERALL_STATUS.ON_HOLD]: 'orangeyYellow',
  [FEE_REQUEST_OVERALL_STATUS.REVIEWED]: 'primary',
  [FEE_REQUEST_OVERALL_STATUS.FEE_REJECTED]: 'danger',
  [FEE_REQUEST_OVERALL_STATUS.DISBURSED]: 'success',
  [FEE_REQUEST_OVERALL_STATUS.REQUEST_SENT]: 'primary',
  [FEE_REQUEST_OVERALL_STATUS.REQUEST_APPROVED]: 'success',
  [FEE_REQUEST_OVERALL_STATUS.WAITING_FOR_CLAIM]: 'orangeyYellow',
  [FEE_REQUEST_OVERALL_STATUS.WAITING_FOR_PENALTY]: 'orangeyYellow',
  [FEE_REQUEST_OVERALL_STATUS.WAITING_FOR_OVERNIGHT]: 'orangeyYellow',
  [FEE_REQUEST_OVERALL_STATUS.WAITING_FOR_TKBM]: 'orangeyYellow',
  [FEE_REQUEST_OVERALL_STATUS.WAITING_FOR_INVOICE]: 'orangeyYellow',
  [FEE_REQUEST_OVERALL_STATUS.WAITING_FOR_INVOICE_REVISION]: 'orangeyYellow',
  [FEE_REQUEST_OVERALL_STATUS.PARTIALLY_DISBURSED]: 'primary',
};

export const FEE_REQUEST_STATUS = {
  PENDING: 'PENDING',
  SHIPPER_PENDING: 'SHIPPER_PENDING',
  ON_HOLD: 'ON_HOLD',
  REVIEWED: 'REVIEWED',
  REQUEST_SENT: 'REQUEST_SENT',
  FEE_REJECTED: 'FEE_REJECTED',
  REQUEST_APPROVED: 'REQUEST_APPROVED',
  REQUEST_REJECTED: 'REQUEST_REJECTED',
  DISBURSED: 'DISBURSED',
  PAID: 'PAID',
  APPROVED: 'APPROVED',
  CANCELLED: 'CANCELLED',
  REJECTED: 'REJECTED',
};

export const FEE_REQUEST_STATUS_COLOR = {
  [FEE_REQUEST_STATUS.PENDING]: 'warning',
  [FEE_REQUEST_STATUS.SHIPPER_PENDING]: 'warning',
  [FEE_REQUEST_STATUS.ON_HOLD]: 'warning',
  [FEE_REQUEST_STATUS.REVIEWED]: 'dark',
  [FEE_REQUEST_STATUS.REQUEST_SENT]: 'dark',
  [FEE_REQUEST_STATUS.FEE_REJECTED]: 'danger',
  [FEE_REQUEST_STATUS.REQUEST_APPROVED]: 'success',
  [FEE_REQUEST_STATUS.REQUEST_REJECTED]: 'danger',
  [FEE_REQUEST_STATUS.DISBURSED]: 'success',
  [FEE_REQUEST_STATUS.PAID]: 'success',
  [FEE_REQUEST_STATUS.APPROVED]: 'success',
  [FEE_REQUEST_STATUS.CANCELLED]: 'dark',
  [FEE_REQUEST_STATUS.REJECTED]: 'danger',
};

export const FEE_REQUEST_STATUS_BLACK_COLOR = [
  FEE_REQUEST_STATUS.PENDING,
  FEE_REQUEST_STATUS.ON_HOLD,
];

export const FEE_REQUEST_ALREADY_REVIEW_STATUS = [
  FEE_REQUEST_STATUS.REVIEWED,
  FEE_REQUEST_STATUS.FEE_REJECTED,
  FEE_REQUEST_STATUS.ON_HOLD,
];

export const FEE_REQUEST_SHOW_STATUS = [
  FEE_REQUEST_STATUS.REVIEWED,
  FEE_REQUEST_STATUS.FEE_REJECTED,
  FEE_REQUEST_STATUS.ON_HOLD,
  FEE_REQUEST_STATUS.PENDING,
];

export const FEE_REQUEST_FINISH_STATUS = [
  FEE_REQUEST_STATUS.REQUEST_SENT,
  FEE_REQUEST_STATUS.REQUEST_APPROVED,
  FEE_REQUEST_STATUS.DISBURSED,
];

export const FEE_REQUEST_NEED_REVIEW_STATUS = [
  FEE_REQUEST_OVERALL_STATUS.REVIEW_SHIPMENT_FEE,
  FEE_REQUEST_OVERALL_STATUS.REVIEW_ADDITIONAL_FEE,
];

export const FEE_REQUEST_VIEW_STATUS = [
  FEE_REQUEST_STATUS.PENDING,
  FEE_REQUEST_STATUS.REVIEWED,
  FEE_REQUEST_STATUS.ON_HOLD,
  FEE_REQUEST_STATUS.REQUEST_SENT,
  FEE_REQUEST_STATUS.DISBURSED,
  FEE_REQUEST_STATUS.REQUEST_APPROVED,
  FEE_REQUEST_STATUS.REQUEST_REJECTED,
];

export const FEE_REQUEST_EDIT_STATUS = [
  FEE_REQUEST_STATUS.PENDING,
  FEE_REQUEST_STATUS.REVIEWED,
  FEE_REQUEST_STATUS.ON_HOLD,
  FEE_REQUEST_STATUS.FEE_REJECTED,
];

export const FEE_REQUEST_CAN_REVIEW_STATUS = [
  ...FEE_REQUEST_NEED_REVIEW_STATUS,
  FEE_REQUEST_OVERALL_STATUS.ON_HOLD,
  FEE_REQUEST_OVERALL_STATUS.REVIEWED,
  FEE_REQUEST_OVERALL_STATUS.REQUEST_SENT,
  ...FEE_REQUEST_FINISH_STATUS,
];

export const PAYMENT_REQUEST_NEED_REVIEW_STATUS = [
  PAYMENT_REQUEST_STATUSES.REVIEW_NOW,
];

export const PAYMENT_REQUEST_CAN_REVIEW_STATUS = [
  ...PAYMENT_REQUEST_NEED_REVIEW_STATUS,
  PAYMENT_REQUEST_STATUSES.ON_HOLD,
  PAYMENT_REQUEST_STATUSES.APPROVED,
  PAYMENT_REQUEST_STATUSES.WAITING_FOR_WITHDRAWAL,
  PAYMENT_REQUEST_STATUSES.WITHDRAWAL_REQUESTED,
  PAYMENT_REQUEST_STATUSES.DISBURSEMENT_FAILED,
  PAYMENT_REQUEST_STATUSES.DISBURSEMENT_PLANNED,
];

export const PAYMENT_REQUEST_CAN_REVISE_STATUS = [
  PAYMENT_REQUEST_STATUSES.REJECTED,
];

export const FEE_REQUEST_TYPE = {
  KARGO: 'KARGO',
  LATE: 'LATE',
  SHIPMENT: 'SHIPMENT',
  LOADING: 'LOADING',
  UNLOADING: 'UNLOADING',
  OVERNIGHT_LOADING: 'OVERNIGHT_LOADING',
  OVERNIGHT_UNLOADING: 'OVERNIGHT_UNLOADING',
  CLAIMS: 'CLAIMS',
  INSURANCE: 'INSURANCE',
  PENALTY: 'PENALTY',
  ADJUSTMENT: 'ADJUSTMENT',
  RETURNS: 'RETURNS',
  ADDITIONAL: 'ADDITIONAL',
  OTHER: 'OTHER',
  TICKET: 'TICKET',
  OTHER_ADJUSTMENT: 'OTHER_ADJUSTMENT',
  THC_L: 'THC_L',
  THC_D: 'THC_D',
  LSS: 'LSS',
  STORAGE: 'STORAGE',
  DEMURRAGE: 'DEMURRAGE',
  THC_LOLO: 'THC_LOLO',
};

export const SHIPMENT_FEE_REQUEST_TYPE = [FEE_REQUEST_TYPE.SHIPMENT];

export const ADDITIONAL_FEE_REQUEST_TYPE = [
  FEE_REQUEST_TYPE.LOADING,
  FEE_REQUEST_TYPE.UNLOADING,
  FEE_REQUEST_TYPE.OVERNIGHT_LOADING,
  FEE_REQUEST_TYPE.OVERNIGHT_UNLOADING,
  FEE_REQUEST_TYPE.RETURNS,
  FEE_REQUEST_TYPE.ADDITIONAL,
  FEE_REQUEST_TYPE.OTHER,
  FEE_REQUEST_TYPE.TICKET,
  FEE_REQUEST_TYPE.THC_L,
  FEE_REQUEST_TYPE.THC_D,
  FEE_REQUEST_TYPE.LSS,
  FEE_REQUEST_TYPE.STORAGE,
  FEE_REQUEST_TYPE.DEMURRAGE,
  FEE_REQUEST_TYPE.THC_LOLO,
];

export const ADDITIONAL_FEE_LOAD_TYPE = [
  FEE_REQUEST_TYPE.LOADING,
  FEE_REQUEST_TYPE.UNLOADING,
  FEE_REQUEST_TYPE.OTHER,
];

export const ADDITIONAL_FEE_OVERNIGHT_TYPE = [
  FEE_REQUEST_TYPE.OVERNIGHT_LOADING,
  FEE_REQUEST_TYPE.OVERNIGHT_UNLOADING,
];

export const ADDITIONAL_FEE_OTHER_TYPE = [
  FEE_REQUEST_TYPE.RETURNS,
  FEE_REQUEST_TYPE.ADDITIONAL,
  FEE_REQUEST_TYPE.TICKET,
];

export const ADJUSTMENT_FEE_NEGATIVE_TYPE = [
  FEE_REQUEST_TYPE.CLAIMS,
  FEE_REQUEST_TYPE.PENALTY,
];

export const ADJUSTMENT_FEE_NO_DUPLICATE_TYPE = [
  FEE_REQUEST_TYPE.CLAIMS,
  FEE_REQUEST_TYPE.INSURANCE,
];

export const ADJUSTMENT_FEE_REQUEST_TYPE = [
  ...ADJUSTMENT_FEE_NEGATIVE_TYPE,
  FEE_REQUEST_TYPE.INSURANCE,
  FEE_REQUEST_TYPE.ADJUSTMENT,
  FEE_REQUEST_TYPE.OTHER_ADJUSTMENT,
];

export const AGREEMENT_STATUS_MAP = {
  AGREEMENT_SIGNED_BY_KARGO: 'AGREEMENT_SIGNED_BY_KARGO',
  WORK_ORDER_SIGNED_BY_BOTH: 'WORK_ORDER_SIGNED_BY_BOTH',
  UNDOCUMENTED: 'UNDOCUMENTED',
  AGREEMENT_SIGNED_BY_BOTH: 'AGREEMENT_SIGNED_BY_BOTH',
  WORK_ORDER_SIGNED_BY_KARGO: 'WORK_ORDER_SIGNED_BY_KARGO',
  QUOTATION_SIGNED_BY_BOTH: 'QUOTATION_SIGNED_BY_BOTH',
  QUOTATION_SIGNED_BY_KARGO: 'QUOTATION_SIGNED_BY_KARGO',
  DRAFT_AGREEMENT: 'DRAFT_AGREEMENT',
};

export const CONTRACT_MAP = {
  CONTRACT_AGREEMENT: 'CONTRACT_AGREEMENT',
  CONTRACT_WORK_ORDER: 'CONTRACT_WORK_ORDER',
};

// LOCATION FEE Constants
export const LOCATION_FEE_TYPE = {
  LOADING: 'LOADING',
  UNLOADING: 'UNLOADING',
  OTHER: 'OTHER',
};

export const LOCATION_FEE_TYPE_ORDERING = {
  LOADING: 1,
  UNLOADING: 0,
  OTHER: 2,
};

export const SHIPPING_LINE_STATUS = {
  TO_BE_PROCESSED: 'TO_BE_PROCESSED',
  VESSEL_BOOKED: 'VESSEL_BOOKED',
  DEPARTURE: 'DEPARTURE',
  ARRIVAL: 'ARRIVAL',
  SHIPPING_LINE_SHIPMENT_COMPLETE: 'SHIPPING_LINE_SHIPMENT_COMPLETE',
  CANCELLED_BY_INTERNAL: 'CANCELLED_BY_INTERNAL',
};

export const DOORING_AGENT_STATUS = {
  UNASSIGNED: 'UNASSIGNED',
  UPCOMING: 'UPCOMING',
  PLANNED: 'PLANNED',

  // Temporarily statuses below are mapped to behaved like ARRIVE_AT_DESTINATION
  ARRIVE_AT_DESTINATION: 'ARRIVE_AT_DESTINATION',
  START_UNLOADING: 'START_UNLOADING',
  FINISH_UNLOADING: 'FINISH_UNLOADING',
  WAITING_FOR_CONFIRMATION: 'WAITING_FOR_CONFIRMATION',

  SHIPMENT_COMPLETE: 'SHIPMENT_COMPLETE',
  CANCELLED_BY_INTERNAL: 'CANCELLED_BY_INTERNAL',
  // Temporarily statuses below are mapped to behaved like ASSIGNED
  ASSIGNED: 'ASSIGNED',
  ONGOING_TO_PICKUP: 'ONGOING_TO_PICKUP',
  ARRIVE_AT_PICKUP: 'ARRIVE_AT_PICKUP',
  START_LOADING: 'START_LOADING',
  FINISH_LOADING: 'FINISH_LOADING',
  ON_SHIPMENT: 'ON_SHIPMENT',
  ONGOING_TO_DESTINATION: 'ONGOING_TO_DESTINATION',
};

export const SHIPPING_LINE_STATUS_COLOR = {
  [SHIPPING_LINE_STATUS.VESSEL_BOOKED]: 'steelBlue',
  [SHIPPING_LINE_STATUS.DEPARTURE]: 'orange',
  [SHIPPING_LINE_STATUS.ARRIVAL]: 'success',
  [SHIPPING_LINE_STATUS.SHIPPING_LINE_SHIPMENT_COMPLETE]: 'grey',
  [SHIPPING_LINE_STATUS.CANCELLED_BY_INTERNAL]: 'danger',
};

export const DOORING_AGENT_STATUS_COLOR = {
  [DOORING_AGENT_STATUS.UPCOMING]: 'orange',
  [DOORING_AGENT_STATUS.PLANNED]: 'steelblue',
  [DOORING_AGENT_STATUS.ASSIGNED]: 'primary',
  [DOORING_AGENT_STATUS.ARRIVE_AT_DESTINATION]: 'success',
  [DOORING_AGENT_STATUS.SHIPMENT_COMPLETE]: 'grey',
  [DOORING_AGENT_STATUS.CANCELLED_BY_INTERNAL]: 'danger',
};

export const SHIPMENT_TYPE = {
  MT: 'MT',
  GT: 'GT',
  W2W: 'W2W',
  F2DEPO: 'F2DEPO',
  F2DIST: 'F2DIST',
  F2MT: 'F2MT',
  F2W: 'F2W',
  W2DEPO: 'W2DEPO',
  W2DIST: 'W2DIST',
  W2MT: 'W2MT',
};

export const SHIPMENT_TYPE_WITH_DTD = {
  ...SHIPMENT_TYPE,
  TRUCKING_DTD: 'TRUCKING DTD',
};

export const FIRST_MILE_SHIPMENT_TYPE = {
  DOOR_TO_DOOR: 'DOOR_TO_DOOR',
  MULTI_DOOR_TO_DOOR: 'MULTI_DOOR_TO_DOOR',
  TRUCKING_DTD: 'TRUCKING_DTD',
  SHIPPING_LINE_DTD: 'SHIPPING_LINE_DTD',
  DOORING_AGENT_DTD: 'DOORING_AGENT_DTD',
  FIRST_MILE_TRUCKING: 'FIRST_MILE_TRUCKING',
};

export const FIRST_MILE_TRUCKING_SHIPMENT_TYPE = {
  TRUCKING_DTD: 'TRUCKING_DTD',
  FIRST_MILE_OUTBOUND: 'FM_OUTBOUND',
  FIRST_MILE_INBOUND: 'FM_INBOUND',
  FIRST_MILE_EXPORT: 'FM_EXPORT',
  FIRST_MILE_IMPORT: 'FM_IMPORT',
};

export const PURCHASE_ORDER_FIRST_MILE_TRUCKING = {
  FIRST_MILE_OUTBOUND: 'FM_OUTBOUND',
  FIRST_MILE_INBOUND: 'FM_INBOUND',
  FIRST_MILE_EXPORT: 'FM_EXPORT',
  FIRST_MILE_IMPORT: 'FM_IMPORT',
};

export const PRICING_RATE_TYPES = {
  PER_CBM: 'PER_CBM',
  PER_KG: 'PER_KG',
  PER_LANE: 'PER_LANE',
  PER_ROUTE: 'PER_ROUTE',
  PER_UNIT: 'PER_UNIT',
};

export const PRICING_RATE_TYPES_MAPPER = {
  [PRICING_RATE_TYPES.PER_CBM]: 'Per CBM',
  [PRICING_RATE_TYPES.PER_KG]: 'Per Kg',
  [PRICING_RATE_TYPES.PER_LANE]: 'Per Lane',
  [PRICING_RATE_TYPES.PER_ROUTE]: 'Per Route',
  [PRICING_RATE_TYPES.PER_UNIT]: 'Per Unit',
};

export const PRICING_LANE_LEVELS = {
  PROVINCE: 'PROVINCE',
  DISTRICT: 'DISTRICT',
  SUBDISTRICT: 'SUBDISTRICT',
};

export const AUTOMATIC_UNFULFILL_TIME_FRAME = {
  latest_loading_time: 'latest_loading_time',
  cutoff_time: 'cutoff_time',
  loading_time: 'loading_time',
};

// need to update the text and value below from the constant above
export const PRICING_RATE_TYPE = [
  { label: 'Per Route', value: 'PER_ROUTE' },
  { label: 'Per Lane', value: 'PER_LANE' },
  { label: 'Per Unit', value: 'PER_UNIT' },
  { label: 'Per Kg', value: 'PER_KG' },
  { label: 'Per CBM', value: 'PER_CBM' },
];

export const PRICING_RATE_TYPE_DIVIDED = {
  PER_UNITS: [
    { label: 'Per Unit', value: 'PER_UNIT' },
    { label: 'Per Kg', value: 'PER_KG' },
    { label: 'Per CBM', value: 'PER_CBM' },
  ],
  PER_ROUTES: [
    { label: 'Per Route', value: 'PER_ROUTE' },
    { label: 'Per Lane', value: 'PER_LANE' },
  ],
};

export const MULTIPLE_SHIPMENT_TYPE = {
  MULTIDROP: 'MULTIDROP',
  MULTIPICKUP: 'MULTIPICKUP',
  MULTIDROP_MULTIPICKUP: 'MULTIDROP_MULTIPICKUP',
};

export const MULTIPLE_SHIPMENT_TYPES = Object.keys(MULTIPLE_SHIPMENT_TYPE);

export const PROOF_OF_CANCELLATION_TYPE = {
  WHATSAPP_APPROVAL: 'WHATSAPP_APPROVAL',
  EMAIL_APPROVAL: 'EMAIL_APPROVAL',
  DRIVER_APP_GPS: 'DRIVER_APP_GPS',
};

export const OVERNIGHT_PROOF_TYPE = {
  SHIPPER_APPROVAL: 'SHIPPER_APPROVAL',
  FORM_OR_BERITA_ACARA_OR_PROOF_OF_OVERNIGHT_STAY:
    'FORM_OR_BERITA_ACARA_OR_PROOF_OF_OVERNIGHT_STAY',
  WAREHOUSE_ENTRY_AND_EXIT_TIMESTAMPS: 'WAREHOUSE_ENTRY_AND_EXIT_TIMESTAMPS',
  DRIVER_APP_GPS_TIMESTAMPS: 'DRIVER_APP_GPS_TIMESTAMPS',
  OTHER: 'OTHER',
};

export const INCLUDE_IN_CALCULATION_MAP = {
  SUNDAYS: 'SUNDAYS',
  NATIONAL_HOLIDAYS: 'NATIONAL_HOLIDAYS',
};

export const PKP_OPTIONS = {
  PKP: 'PKP',
  NON_PKP: 'NON_PKP',
};

export const TOTAL_SECONDS_IN_DAY = 86400;
export const TOTAL_SECONDS_IN_HOUR = 3600;
export const TOTAL_MINUTE_IN_HOUR = 60;

export const REIMBURSEMENT_REQUIREMENT_TYPE = {
  REIMBURSABLE_WITH_RECEIPT_AND_STAMP: 'REIMBURSABLE_WITH_RECEIPT_AND_STAMP',
  REIMBURSABLE_WITH_RECEIPT: 'REIMBURSABLE_WITH_RECEIPT',
  REIMBURSABLE: 'REIMBURSABLE',
  NOT_REIMBURSABLE: 'NOT_REIMBURSABLE',
  OTHER: 'OTHER',
};

export const REIMBURSEMENT_TYPE = {
  ROUTE: 'ROUTE',
  UNIT: 'UNIT',
  KG: 'KG',
  OTHER: 'OTHER',
};

export const CAPACITY_UNIT = {
  KG: 'KG',
  CBM: 'CBM',
  UNIT: 'UNIT',
  TON: 'TON',
};

export const SHIPMENT_QUANTITY_UNITS = {
  KG: CAPACITY_UNIT.KG,
  CBM: CAPACITY_UNIT.CBM,
  UNIT: CAPACITY_UNIT.UNIT,
  TON: CAPACITY_UNIT.TON,
  BOX: 'BOX',
  CARTON: 'CARTON',
  PCS: 'PCS',
  CASES: 'CASES',
  KOLI: 'KOLI',
  DRUM: 'DRUM',
  BAL: 'BAL',
  ROLL: 'ROLL',
  BAG: 'BAG',
  BOTTLE: 'BOTTLE',
  ZAK: 'ZAK',
  METER: 'METER',
};

export const CAPACITY_UNIT_MAPPER = {
  [CAPACITY_UNIT.KG]: 'Kg',
  [CAPACITY_UNIT.CBM]: 'CBM',
  [CAPACITY_UNIT.UNIT]: 'Unit',
};

export const CAPACITY_UNIT_OPTIONS = [
  { label: CAPACITY_UNIT_MAPPER[CAPACITY_UNIT.KG], value: CAPACITY_UNIT.KG },
  { label: CAPACITY_UNIT_MAPPER[CAPACITY_UNIT.CBM], value: CAPACITY_UNIT.CBM },
  {
    label: CAPACITY_UNIT_MAPPER[CAPACITY_UNIT.UNIT],
    value: CAPACITY_UNIT.UNIT,
  },
];

export const DELIVERY_ORDER_WAREHOUSE_FILTER_TYPE = {
  INBOUND: 'inbound',
  OUTBOUND: 'outbound',
  ALL: 'all',
};

export const DELIVERY_ORDER_LOAD_TYPE = {
  NON_GROUP_LOAD: 'NON_GROUP_LOAD',
  GROUP_LOAD: 'GROUP_LOAD',
};

export const TRANSPORTER_VERIFICATION_LEVEL = {
  CREATED: 'L0',
  VERIFIED: 'L2',
  TRUSTED: 'L3',
};

export const CUSTOM_CITY_REGION_MAPPING_METADATA = 'custom_city_region_mapping';
export const CUSTOM_CITY_CONSTANT_NAME = 'CITY';
export const CUSTOM_REGION_CONSTANT_NAME = 'REGION';
export const CUSTOM_LOCATION_GROUP_NAME = 'LOCATION_GROUP';
export const CUSTOM_QUANTITY_TYPE_NAME = 'QUANTITY_TYPE';
export const CUSTOM_PRODUCT_GROUP_NAME = 'PRODUCT_GROUP';
export const CUSTOM_COMPANY_ROLES_METADATA = 'company_roles';

export const OLD_TRUCK_BRANDS = {
  DAIHATSU: 'Daihatsu',
  HINO: 'Hino',
  ISUZU: 'Isuzu',
  SCANIA: 'Scania',
  TOYOTA: 'Toyota',
  MITSUBISHI: 'Mitsubishi',
};

export const TRUCK_BRANDS = {
  DAIHATSU: 'Daihatsu',
  HINO: 'Hino',
  ISUZU: 'Isuzu',
  MERCEDES_BENZ: 'Mercedes Benz',
  MITSUBISHI: 'Mitsubishi',
  NISSAN: 'Nissan',
  SCANIA: 'Scania',
  TOYOTA: 'Toyota',
};

export const VEHICLE_DOCUMENTS = {
  KIR: {
    name: 'KIR',
    necessityType: 'OPTIONAL',
  },
  STNK: {
    name: 'STNK',
    necessityType: 'OPTIONAL',
  },
};

export const DRIVER_DOCUMENTS = {
  KTP: 'KTP',
  SIM: 'SIM',
  NRIC: 'NRIC',
  GDL: 'GDL',
};

export const DRIVER_DOCUMENTS_MAP = {
  [COUNTRIES.ID]: {
    ID_CARD: DRIVER_DOCUMENTS.KTP,
    DRIVING_LICENSE: DRIVER_DOCUMENTS.SIM,
  },
  [COUNTRIES.MY]: {
    ID_CARD: DRIVER_DOCUMENTS.NRIC,
    DRIVING_LICENSE: DRIVER_DOCUMENTS.GDL,
  },
};

export const ADDRESS_TYPE = {
  BILLING: 'BILLING',
  COMPANY: 'COMPANY',
  POOL: 'POOL',
};

export const TRANSPORTER_ACCOUNT_TYPES = {
  PERSONAL: 'PERSONAL',
  COMPANY: 'COMPANY',
};

export const DOCUMENT_VERIFICATION_REJECTION_REASONS = {
  BLUR: 'BLUR',
  DOCUMENT_INCOMPLETE: 'DOCUMENT_INCOMPLETE',
  EXPIRED: 'EXPIRED',
  WRONG_DOCUMENT: 'WRONG_DOCUMENT',
  OTHER: 'OTHER',
};

export const TRANSPORTER_COMPANY_REJECTION_REASONS = {
  DOCS_REUPLOAD_NEEDED: 'DOCS_REUPLOAD_NEEDED',
  OTHER_REASON: 'OTHER_REASON',
};

export const VEHICLE_COMPANY_REJECTION_REASONS = {
  DOCS_REUPLOAD_NEEDED: 'DOCS_REUPLOAD_NEEDED',
  OTHER_REASON: 'OTHER_REASON',
};

export const DRIVER_COMPANY_REJECTION_REASONS = {
  DOCS_REUPLOAD_NEEDED: 'DOCS_REUPLOAD_NEEDED',
  OTHER_REASON: 'OTHER_REASON',
};

export const DANONE_USER_ACCESS_TYPE = {
  DANONE_LOGISTIC: 'DANONE_LOGISTIC',
  DANONE_PROCUREMENT: 'DANONE_PROCUREMENT',
};

/**
 * @deprecated
 *
 * No more usage for S_TMS_TRACKER_EVENTS.
 * For STMS, please use TRACKER_EVENTS from packages/web-shipper/src/Configurations/constants.js.
 * Remove S_TMS_TRACKER_EVENTS when all the legacy codes migrated to the new constant.
 */
export const S_TMS_TRACKER_EVENTS = {
  BULK_DO_CLICKED: 'Bulk DO clicked',
  BULK_DO_SUBMITTED: 'Bulk DO submitted',
  CHOOSE_COMPANY: 'Choose company',
  DO_DETAIL_ASSIGN: 'DO detail assign',
  DO_LIST_ASSIGN: 'DO list assign',
  DO_LIST_EXPANDED: 'DO list expanded',
  DO_LIST_REASSIGN: 'DO list reassign',
  DOWNLOAD_INSIGHTS_CSV: 'Download insights CSV',
  INSIGHTS_FILTER_CHANGED: 'Insight filter changed',
  INSIGHTS_CHART_TARGET_CHANGED: 'Insight chart target changed',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  MAP_MARKER_SELECTED: 'Map marker selected',
  MAP_SEARCH_KEYWORD_APPLIED: 'Map searchKeyword filter applied',
  MAP_SHIPMENT_DIRECTION_APPLIED: 'Map shipment direction filter applied',
  MAP_STATUS_APPLIED: 'Map status filter applied',
  MAP_WAREHOUSE_APPLIED: 'Map warehouse filter applied',
  NAVIGATION_CHANGED: 'Navigation changed',
  ONSITE_NOTIF_OPENED: 'Onsite notification opened',
  SINGLE_DO_CLICKED: 'Single DO clicked',
  SINGLE_DO_SUBMITTED: 'Single DO Submitted',
};

/**
 * T-TMS Tracker Event Constants
 *
 * @remark
 * Keywords are separated by double underscores __
 * Spaces are labelled by single underscores _
 *
 * Key naming follows this convention:
 * {Event}__{Module}__{Component + Action}
 *
 * Value follows the same convention (in lowercase):
 * '{Event}__{Module}__{Component + Action}'
 */
export const T_TMS_TRACKER_EVENTS = {
  DISCOVERY__JOB__ACCEPT_NOW_POPUP_VIEWED: 'discovery__job__accept_now_popup_viewed',
  DISCOVERY__JOB__BID_POPUP_VIEWED: 'discovery__job__bid_popup_viewed',
  DISCOVERY__JOB__VIEWED: 'discovery__job__viewed',
  GENERAL__NOTIFICATIONS__ACTION_CLICKED: 'general__notifications__action_clicked',
  NAVIGATION__PAGE__VISITED: 'navigation__page__visited',
};

// Used to identify action codes for clevertap 'notification__action' trackers
export const NOTIFICATION_ACTION_CODE = {
  VIEW_DO_DETAILS: '5-32',
};

export const MEASUREMENT_UNIT = {
  KG: 'Kg',
  MM: 'mm',
};

export const DRIVER_LICENSE_TYPE = {
  SIM_A: 'A',
  SIM_A_UMUM: 'A UMUM',
  SIM_BI: 'B I',
  SIM_BI_UMUM: 'B I UMUM',
  SIM_BII: 'B II',
  SIM_BII_UMUM: 'B II UMUM',
};

export const SHIPMENT_ACTIVITY_LOG_STATUSES = {
  UPDATE_VIA: {
    UPDATE_BY_CALL: 'UPDATE_BY_CALL',
    UPDATE_BY_TRANSPORTER: 'UPDATE_BY_TRANSPORTER',
    UPDATE_BY_WHATSAPP: 'UPDATE_BY_WHATSAPP',
  },
  SHIPMENT_STAGE: {
    BEFORE_PICKUP: 'BEFORE_PICKUP',
    PICKUP: 'PICKUP',
    ON_SHIPMENT: 'ON_SHIPMENT',
    DESTINATION: 'DESTINATION',
  },
  PROBLEM: {
    DRIVER_NO_RESPONSE: 'DRIVER_NO_RESPONSE',
    GPS_NOT_ACTIVE: 'GPS_NOT_ACTIVE',
    INCORRECT_POSITION: 'INCORRECT_POSITION',
    ISSUES: 'ISSUES',
    LATE_SHIPMENT: 'LATE_SHIPMENT',
    REMINDER_ON_TIME_PICKUP: 'REMINDER_ON_TIME_PICKUP',
  },
};

export const TRUCKING_SHIPMENT_ACTIVITY_LOG_STATUSES = {
  UPDATE_VIA: {
    UPDATE_BY_CALL: 'UPDATE_BY_CALL',
    UPDATE_BY_WHATSAPP: 'UPDATE_BY_WHATSAPP',
    UPDATE_BY_TRANSPORTER: 'UPDATE_BY_TRANSPORTER',
    UPDATE_BY_DRIVER: 'UPDATE_BY_DRIVER',
  },
  SHIPMENT_STAGE: {
    PRE_SHIPMENT: 'PRE_SHIPMENT',
    ON_SHIPMENT: 'ON_SHIPMENT',
    POST_SHIPMENT: 'POST_SHIPMENT',
  },
  PROBLEM: {
    ISSUES: 'ISSUES',
    LATE_SHIPMENT: 'LATE_SHIPMENT',
    TPT_NO_RESPONSE: 'TPT_NO_RESPONSE',
  },
};

export const DA_SHIPMENT_ACTIVITY_LOG_STATUSES = {
  UPDATE_VIA: {
    UPDATE_BY_CALL: 'UPDATE_BY_CALL',
    UPDATE_BY_WHATSAPP: 'UPDATE_BY_WHATSAPP',
    UPDATE_BY_TRANSPORTER: 'UPDATE_BY_TRANSPORTER',
    UPDATE_BY_DRIVER: 'UPDATE_BY_DRIVER',
  },
  SHIPMENT_STAGE: {
    PRE_SHIPMENT: 'PRE_SHIPMENT',
    ON_SHIPMENT: 'ON_SHIPMENT',
    POST_SHIPMENT: 'POST_SHIPMENT',
  },
  PROBLEM: {
    ISSUES: 'ISSUES',
    LATE_SHIPMENT: 'LATE_SHIPMENT',
    TPT_NO_RESPONSE: 'TPT_NO_RESPONSE',
  },
};

export const SPL_SHIPMENT_ACTIVITY_LOG_STATUSES = {
  UPDATE_VIA: {
    UPDATE_BY_CALL: 'UPDATE_BY_CALL',
    UPDATE_BY_WHATSAPP: 'UPDATE_BY_WHATSAPP',
    UPDATE_BY_DOORING: 'UPDATE_BY_DOORING',
  },
  SHIPMENT_STAGE: {
    BOOKED: 'BOOKED',
    DEPARTURE: 'DEPARTURE',
    ARRIVED: 'ARRIVED',
    BERTHING: 'BERTHING',
  },
  PROBLEM: {
    INCORRECT_POSITION: 'INCORRECT_POSITION',
    ISSUES: 'ISSUES',
    LATE_SHIPMENT: 'LATE_SHIPMENT',
  },
};

export const SHIPMENT_DOCUMENT_REJECTION_REASONS = {
  UNCLEAR: 'UNCLEAR',
  DARK: 'DARK',
  ADDRESS_UNCLEAR: 'ADDRESS_UNCLEAR',
  CROPPED: 'CROPPED',
  OTHERS: 'OTHERS',
};

export const MAP_SHIPMENT_STATUS = {
  ONGOING_TO_PICKUP: 'ONGOING_TO_PICKUP',
  ON_SHIPMENT: 'ON_SHIPMENT',
};

export const MAP_SHIPMENT_STATUS_COLORS = {
  [MAP_SHIPMENT_STATUS.ONGOING_TO_PICKUP]: 'success',
  [MAP_SHIPMENT_STATUS.ON_SHIPMENT]: 'orangeyYellow',
};

export const ON_TIME_STATUS = {
  LATE: 'LATE',
  MAYBE_LATE: 'MAYBE_LATE',
  ONTIME: 'ONTIME',
};

export const ON_TIME_STATUS_COLOR = {
  [ON_TIME_STATUS.LATE]: 'danger',
  [ON_TIME_STATUS.MAYBE_LATE]: 'orangeyYellow',
  [ON_TIME_STATUS.ONTIME]: 'darkGrey',
};

export const ON_TIME_STATUS_COLOR_HEX = {
  [ON_TIME_STATUS.LATE]: '#dc4545',
  [ON_TIME_STATUS.MAYBE_LATE]: '#ffbe1a',
  [ON_TIME_STATUS.ONTIME]: '#31a343',
};

export const GPS_STATUS = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
};

export const SHIPPER_INVOICE_ACTIVITY_TYPE = {
  REMINDER_SENT: 'REMINDER_SENT',
  SHIPPER_PAID: 'SHIPPER_PAID',
  PAYMENT_PLANNED: 'PAYMENT_PLANNED',
  LEGAL: 'LEGAL',
  RESTRUCTURING: 'RESTRUCTURING',
  INSTALLMENT: 'INSTALLMENT',
  ON_HOLD: 'ON_HOLD',
  DEPRECATED: 'DEPRECATED',
};

export const SHIPPER_INVOICE_ACTIVITY_TYPE_COLOR = {
  REMINDER_SENT: 'primary',
  SHIPPER_PAID: 'success',
  PAYMENT_PLANNED: 'primary',
  LEGAL: 'danger',
  RESTRUCTURING: 'danger',
  INSTALLMENT: 'danger',
  ON_HOLD: 'warning',
  DEPRECATED: 'danger',
};

export const BROKERAGE_SHIPPER_INVOICE_STATUS = {
  INVOICE_GENERATED: 'INVOICE_GENERATED',
  FINANCE_APPROVED: 'FINANCE_APPROVED',
  PROFORMA_ISSUED: 'PROFORMA_ISSUED',
  PROFORMA_APPROVED: 'PROFORMA_APPROVED',
  INVOICE_SENT: 'INVOICE_SENT',
  INVOICE_RECEIVED: 'INVOICE_RECEIVED',
  SHIPPER_APPROVED: 'SHIPPER_APPROVED',
  PAID: 'PAID',
  PARTIAL_PAID: 'PARTIAL_PAID',
  INSTALLMENT: 'INSTALLMENT',
  RESTRUCTURING: 'RESTRUCTURING',
  LEGAL: 'LEGAL',
  DEPRECATED: 'DEPRECATED',
  FINANCE_REJECTED: 'FINANCE_REJECTED',
  SHIPPER_REJECTED: 'SHIPPER_REJECTED',
  CANCELLED: 'CANCELLED',
  PROFORMA_REJECTED: 'PROFORMA_REJECTED',
  BAD_DEBT: 'BAD_DEBT',
};

export const BROKERAGE_INVOICE_TRACKING_SORT_VALUE = {
  INVOICE_GENERATED: 1,
  FINANCE_REJECTED: 2,
  FINANCE_APPROVED: 2,
  PROFORMA_ISSUED: 3,
  PROFORMA_APPROVED: 4,
  PROFORMA_REJECTED: 4,
  INVOICE_SENT: 5,
  INVOICE_RECEIVED: 6,
  SHIPPER_APPROVED: 7,
  SHIPPER_REJECTED: 7,
  PAID: 8,
  INSTALLMENT: 8,
  RESTRUCTURING: 8,
  LEGAL: 9,
  DEPRECATED: 9,
};

export const BROKERAGE_SHIPPER_INVOICE_PAID_STATUS = [
  BROKERAGE_SHIPPER_INVOICE_STATUS.PAID,
  BROKERAGE_SHIPPER_INVOICE_STATUS.PARTIAL_PAID,
];

export const BROKERAGE_SHIPPER_INVOICE_ADD_PAYMENTS = [
  ...BROKERAGE_SHIPPER_INVOICE_PAID_STATUS,
  BROKERAGE_SHIPPER_INVOICE_STATUS.INSTALLMENT,
  BROKERAGE_SHIPPER_INVOICE_STATUS.RESTRUCTURING,
];

export const BROKERAGE_SHIPPER_INVOICE_STATUS_WITH_FILES = [
  BROKERAGE_SHIPPER_INVOICE_STATUS.INVOICE_SENT,
  BROKERAGE_SHIPPER_INVOICE_STATUS.INVOICE_RECEIVED,
  BROKERAGE_SHIPPER_INVOICE_STATUS.SHIPPER_APPROVED,
  BROKERAGE_SHIPPER_INVOICE_STATUS.INSTALLMENT,
  BROKERAGE_SHIPPER_INVOICE_STATUS.RESTRUCTURING,
];

export const BROKERAGE_SHIPPER_INVOICE_STATUS_BUTTON = {
  [BROKERAGE_SHIPPER_INVOICE_STATUS.INVOICE_GENERATED]: 'review_invoice',
  [BROKERAGE_SHIPPER_INVOICE_STATUS.FINANCE_APPROVED]: 'send_invoice',
  [BROKERAGE_SHIPPER_INVOICE_STATUS.PROFORMA_ISSUED]: 'approve_proforma',
  [BROKERAGE_SHIPPER_INVOICE_STATUS.PROFORMA_APPROVED]: 'send_invoice',
  [BROKERAGE_SHIPPER_INVOICE_STATUS.PROFORMA_REJECTED]: 'edit_invoice',
  [BROKERAGE_SHIPPER_INVOICE_STATUS.INVOICE_SENT]: 'shipper_received',
  [BROKERAGE_SHIPPER_INVOICE_STATUS.INVOICE_RECEIVED]: 'shipper_approved',
  [BROKERAGE_SHIPPER_INVOICE_STATUS.SHIPPER_APPROVED]: 'mark_as_paid',
  [BROKERAGE_SHIPPER_INVOICE_STATUS.RESTRUCTURING]: 'add_payment',
  [BROKERAGE_SHIPPER_INVOICE_STATUS.PAID]: 'cancelled',
  [BROKERAGE_SHIPPER_INVOICE_STATUS.PARTIAL_PAID]: 'add_payment',
  [BROKERAGE_SHIPPER_INVOICE_STATUS.INSTALLMENT]: 'add_payment',
  [BROKERAGE_SHIPPER_INVOICE_STATUS.FINANCE_REJECTED]: 'edit_invoice',
  [BROKERAGE_SHIPPER_INVOICE_STATUS.SHIPPER_REJECTED]: 'revise',
  [BROKERAGE_SHIPPER_INVOICE_STATUS.CANCELLED]: 'revise',
};

export const BROKERAGE_SHIPPER_INVOICE_STATUS_COLOR = {
  INVOICE_GENERATED: 'warning',
  FINANCE_APPROVED: 'primary',
  PROFORMA_ISSUED: 'primary',
  PROFORMA_APPROVED: 'primary',
  INVOICE_SENT: 'primary',
  INVOICE_RECEIVED: 'primary',
  SHIPPER_APPROVED: 'primary',
  PAID: 'success',
  PARTIAL_PAID: 'danger',
  INSTALLMENT: 'danger',
  RESTRUCTURING: 'danger',
  LEGAL: 'danger',
  DEPRECATED: 'danger',
  FINANCE_REJECTED: 'danger',
  SHIPPER_REJECTED: 'danger',
  CANCELLED: 'danger',
  PROFORMA_REJECTED: 'danger',
  BAD_DEBT: 'danger',
};

export const INVOICE_DISPLAY_TYPE = {
  LIST: 'LIST',
  SUMMARY: 'SUMMARY',
};

export const INVOICE_DELIVERY_SERVICES = {
  JNE: 'JNE',
  GOSEND: 'GOSEND',
  GRAB_EXPRESS: 'GRAB_EXPRESS',
  INTERNAL_COURIER: 'INTERNAL_COURIER',
  EMAIL: 'EMAIL',
  OTHER: 'OTHER',
};

export const BROKERAGE_SHIPPER_INVOICE_NEXT_STATUS = {
  INVOICE_GENERATED: 'FINANCE_APPROVED',
  FINANCE_APPROVED: 'INVOICE_SENT',
  PROFORMA_ISSUED: 'PROFORMA_APPROVED',
  PROFORMA_APPROVED: 'INVOICE_SENT',
  PROFORMA_REJECTED: 'INVOICE_GENERATED',
  INVOICE_SENT: 'INVOICE_RECEIVED',
  INVOICE_RECEIVED: 'SHIPPER_APPROVED',
  SHIPPER_APPROVED: 'PAID',
  RESTRUCTURING: 'RESTRUCTURING',
  PAID: 'CANCELLED',
  PARTIAL_PAID: 'PAID',
  INSTALLMENT: 'INSTALLMENT',
  FINANCE_REJECTED: 'INVOICE_GENERATED',
  SHIPPER_REJECTED: 'INVOICE_GENERATED',
  CANCELLED: 'INVOICE_GENERATED',
};

export const DOCUMENT_TYPE_ADD_DOCUMENT = {
  CLAIM_AND_RETURN: 'CLAIM_AND_RETURN',
  OVERNIGHT_LOADING: 'OVERNIGHT_LOADING',
  OVERNIGHT_UNLOADING: 'OVERNIGHT_UNLOADING',
  LOADING_FEE: 'LOADING_FEE',
  UNLOADING_FEE: 'UNLOADING_FEE',
  OTHER_FEE: 'OTHER_FEE',
  OTHER_LOCATION_FEE: 'OTHER_LOCATION_FEE',
};

export const DOCUMENT_TYPE_ADD_DOCUMENT_WITH_LOCATION = [
  DOCUMENT_TYPE_ADD_DOCUMENT.LOADING_FEE,
  DOCUMENT_TYPE_ADD_DOCUMENT.UNLOADING_FEE,
  DOCUMENT_TYPE_ADD_DOCUMENT.OVERNIGHT_LOADING,
  DOCUMENT_TYPE_ADD_DOCUMENT.OVERNIGHT_UNLOADING,
  DOCUMENT_TYPE_ADD_DOCUMENT.OTHER_LOCATION_FEE,
];

export const CLAIM_FORM_CHECKBOX_OPTIONS = {
  RECEIVED_AT_PITSTOP: 'RECEIVED_AT_PITSTOP',
  RETURNED_TO_SHIPPER: 'RETURNED_TO_SHIPPER',
};

export const AGING_PERIOD_TYPE = [
  { label: 'Bad Debt', value: 'bad_debt' },
  { label: 'Not Yet Due', value: 'not_due' },
  { label: '1-15 Days', value: '1-15' },
  { label: '16-30 Days', value: '16-30' },
  { label: '31-60 Days', value: '31-60' },
  { label: '61-90 Days', value: '61-90' },
  { label: '> 90 Days', value: '>90' },
];

export const TON_TO_KG_MULTIPLIER = 1000;

export const MONITORING_STATUS_OPTIONS = {
  REMINDER_SENT: 'Reminder Sent',
  SHIPPER_PAID: 'Shipper Paid',
  PAYMENT_PLANNED: 'Payment Planned',
  LEGAL: 'Legal',
  RESTRUCTURING: 'Restructuring',
  INSTALLMENT: 'Installment',
  ON_HOLD: 'On Hold',
  DEPRECATED: 'Deprecated',
};

export const REMINDER_VIA = {
  EMAIL: 'EMAIL',
  WHATSAPP: 'WHATSAPP',
};

export const DO_INVOICE_FEE_TYPES = {
  SHIPMENT_FEE: 'SHIPMENT_FEE',
  SHIPMENT_FEE_ADJUSTMENT: 'SHIPMENT_FEE_ADJUSTMENT',
  LOADING_FEE: 'LOADING_FEE',
  UNLOADING_FEE: 'UNLOADING_FEE',
  OVERNIGHT_LOADING_FEE: 'OVERNIGHT_LOADING_FEE',
  OVERNIGHT_UNLOADING_FEE: 'OVERNIGHT_UNLOADING_FEE',
  CANCELLATION_FEE: 'CANCELLATION_FEE',
  MULTIPICKUP_FEE: 'MULTIPICKUP_FEE',
  MULTIDROPOFF_FEE: 'MULTIDROPOFF_FEE',
  OTHER_FEE: 'OTHER_FEE',
  OTHER_LOCATION_FEE: 'OTHER_LOCATION_FEE',
};

export const INVOICE_FEE_TYPES = {
  ...DO_INVOICE_FEE_TYPES,
  ...DTD_TRANSLATED_FEE_TYPE,
};

export const INVOICE_TYPE_ENUM = {
  [DO_INVOICE_FEE_TYPES.SHIPMENT_FEE]: 'SHIPMENT_FEE',
  [DO_INVOICE_FEE_TYPES.LOADING_FEE]: 'LOADING_FEE',
  [DO_INVOICE_FEE_TYPES.UNLOADING_FEE]: 'UNLOADING_FEE',
  [DO_INVOICE_FEE_TYPES.OVERNIGHT_LOADING_FEE]: 'OVERNIGHT_LOADING',
  [DO_INVOICE_FEE_TYPES.CANCELLATION_FEE]: 'CANCELLATION',
  [DO_INVOICE_FEE_TYPES.OVERNIGHT_UNLOADING_FEE]: 'OVERNIGHT_UNLOADING',
  [DO_INVOICE_FEE_TYPES.OTHER_FEE]: 'OTHER_FEE',
  [DO_INVOICE_FEE_TYPES.OTHER_LOCATION_FEE]: 'OTHER_LOCATION_FEE',
  [DO_INVOICE_FEE_TYPES.MULTIPICKUP_FEE]: 'MULTIPICKUP',
  [DO_INVOICE_FEE_TYPES.MULTIDROPOFF_FEE]: 'MULTIDROPOFF',
  [DTD_SAVED_INVOICE_FEE_TYPE.TRUCKING_LOADING_FEE]: 'LOADING_FEE',
  [DTD_SAVED_INVOICE_FEE_TYPE.TRUCKING_UNLOADING_FEE]: 'UNLOADING_FEE',
  [DTD_SAVED_INVOICE_FEE_TYPE.TRUCKING_OVERNIGHT_LOADING_FEE]: 'OVERNIGHT_LOADING',
  [DTD_SAVED_INVOICE_FEE_TYPE.TRUCKING_OVERNIGHT_UNLOADING_FEE]: 'OVERNIGHT_UNLOADING',
  [DTD_SAVED_INVOICE_FEE_TYPE.TRUCKING_CANCELLATION_FEE]: 'CANCELLATION',
  [DTD_SAVED_INVOICE_FEE_TYPE.TRUCKING_MULTIPICKUP_FEE]: 'MULTIPICKUP',
  [DTD_SAVED_INVOICE_FEE_TYPE.TRUCKING_MULTIDROPOFF_FEE]: 'MULTIDROPOFF',
  [DTD_SAVED_INVOICE_FEE_TYPE.TRUCKING_OTHER_FEE]: 'OTHER_FEE',
  [DTD_SAVED_INVOICE_FEE_TYPE.TRUCKING_OTHER_LOCATION_FEE]: 'OTHER_LOCATION_FEE',
  [DTD_SAVED_INVOICE_FEE_TYPE.DOORING_LOADING_FEE]: 'LOADING_FEE',
  [DTD_SAVED_INVOICE_FEE_TYPE.DOORING_UNLOADING_FEE]: 'UNLOADING_FEE',
  [DTD_SAVED_INVOICE_FEE_TYPE.DOORING_OVERNIGHT_LOADING_FEE]: 'OVERNIGHT_LOADING',
  [DTD_SAVED_INVOICE_FEE_TYPE.DOORING_OVERNIGHT_UNLOADING_FEE]: 'OVERNIGHT_UNLOADING',
  [DTD_SAVED_INVOICE_FEE_TYPE.DOORING_CANCELLATION_FEE]: 'CANCELLATION',
  [DTD_SAVED_INVOICE_FEE_TYPE.DOORING_MULTIPICKUP_FEE]: 'MULTIPICKUP',
  [DTD_SAVED_INVOICE_FEE_TYPE.DOORING_MULTIDROPOFF_FEE]: 'MULTIDROPOFF',
  [DTD_SAVED_INVOICE_FEE_TYPE.DOORING_OTHER_FEE]: 'OTHER_FEE',
  [DTD_SAVED_INVOICE_FEE_TYPE.DOORING_OTHER_LOCATION_FEE]: 'OTHER_LOCATION_FEE',
  [DTD_SAVED_INVOICE_FEE_TYPE.THC_LOLO]: 'THC_LOLO',
  [DTD_SAVED_INVOICE_FEE_TYPE.STORAGE_FEE]: 'STORAGE_FEE',
  [DTD_SAVED_INVOICE_FEE_TYPE.DEMURRAGE_FEE]: 'DEMURRAGE_FEE',
};

export const multiPickupDropoffType = [
  DO_INVOICE_FEE_TYPES.MULTIPICKUP_FEE,
  DO_INVOICE_FEE_TYPES.MULTIDROPOFF_FEE,
  ...dtdMultiPickupDropoff,
];

export const loadingUnloadingType = [
  DO_INVOICE_FEE_TYPES.LOADING_FEE,
  DO_INVOICE_FEE_TYPES.UNLOADING_FEE,
];

export const overnightLoadingUnloadingType = [
  DO_INVOICE_FEE_TYPES.OVERNIGHT_LOADING_FEE,
  DO_INVOICE_FEE_TYPES.OVERNIGHT_UNLOADING_FEE,
];

export const overnightAndLoading = [
  ...loadingUnloadingType,
  ...overnightLoadingUnloadingType,
];

export const SHIPMENT_POINT_STATUS = {
  PENDING: 'PENDING',
  ARRIVE_AT_DESTINATION: 'ARRIVE_AT_DESTINATION',
  ARRIVE_AT_PICKUP: 'ARRIVE_AT_PICKUP',
  FINISH_LOADING: 'FINISH_LOADING',
  FINISH_UNLOADING: 'FINISH_UNLOADING',
  COMPLETE: 'COMPLETE',
};

export const POD_STATUS = {
  ARRIVING_AT_PITSTOP: 'ARRIVING_AT_PITSTOP',
  RECEIVED: 'RECEIVED',
  DOORING_RECEIVED: 'DOORING_RECEIVED',
  DELIVERING_TO_DOORING: 'DELIVERING_TO_DOORING',
  DELIVERING_TO_AR: 'DELIVERING_TO_AR',
  DELIVERING_TO_SHIPPER: 'DELIVERING_TO_SHIPPER',
  DELIVERING_TO_AR_AND_SHIPPER: 'DELIVERING_TO_AR_AND_SHIPPER',
  DELIVERED_TO_DOORING: 'DELIVERED_TO_DOORING',
  DELIVERED_TO_AR: 'DELIVERED_TO_AR',
  DELIVERED_TO_SHIPPER: 'DELIVERED_TO_SHIPPER',
  DELIVERED_TO_AR_AND_SHIPPER: 'DELIVERED_TO_AR_AND_SHIPPER',
};

export const PODC_PHYSICAL_STATUS = {
  NOT_RECEIVED: 'NOT_RECEIVED',
  RECEIVED: 'RECEIVED',
  DOORING_RECEIVED: 'DOORING_RECEIVED',
  DELIVERING_TO_DOORING: 'DELIVERING_TO_DOORING',
  DELIVERED_TO_DOORING: 'DELIVERED_TO_DOORING',
  DELIVERING_TO_AR: 'DELIVERING_TO_AR',
  DELIVERED_TO_AR: 'DELIVERED_TO_AR',
  DELIVERING_TO_SHIPPER: 'DELIVERING_TO_SHIPPER',
  DELIVERED_TO_SHIPPER: 'DELIVERED_TO_SHIPPER',
  DELIVERING_TO_AR_AND_DELIVERING_TO_SHIPPER: 'DELIVERING_TO_AR_AND_DELIVERING_TO_SHIPPER',
  DELIVERING_TO_AR_AND_DELIVERED_TO_SHIPPER: 'DELIVERING_TO_AR_AND_DELIVERED_TO_SHIPPER',
  DELIVERED_TO_AR_AND_DELIVERING_TO_SHIPPER: 'DELIVERED_TO_AR_AND_DELIVERING_TO_SHIPPER',
  DELIVERED_TO_AR_AND_DELIVERED_TO_SHIPPER: 'DELIVERED_TO_AR_AND_DELIVERED_TO_SHIPPER',
};

export const PODC_DIGITAL_STATUS = {
  NOT_UPLOADED: 'NOT_UPLOADED',
  PARTIALLY_UPLOADED: 'PARTIALLY_UPLOADED',
  FULLY_UPLOADED: 'FULLY_UPLOADED',
  PARTIALLY_REVIEWED: 'PARTIALLY_REVIEWED',
  REJECTED: 'REJECTED',
  PASS_REVIEW: 'PASS_REVIEW',
};

/**
 * Based on design, unique color physical status
 * just for status NOT_RECEIVED
 * making this object will make flexible if in the future (if kargo still exist)
 * next developer can add color more easily
 */
export const PODC_PHYSICAL_STATUS_COLOR = {
  [PODC_PHYSICAL_STATUS.NOT_RECEIVED]: 'danger',
};

export const POD_STATUS_COLORS = {
  [PODC_PHYSICAL_STATUS.NOT_RECEIVED]: 'warning',
  [POD_STATUS.ARRIVING_AT_PITSTOP]: 'warning',
  [POD_STATUS.RECEIVED]: 'primary',
  [POD_STATUS.DELIVERING_TO_AR]: 'warning',
  [POD_STATUS.DELIVERING_TO_SHIPPER]: 'warning',
  [POD_STATUS.DELIVERED_TO_AR]: 'success',
  [POD_STATUS.DELIVERED_TO_SHIPPER]: 'success',
};

export const PODC_DIGITAL_STATUS_COLOR = {
  [PODC_DIGITAL_STATUS.NOT_UPLOADED]: 'danger',
  [PODC_DIGITAL_STATUS.REJECTED]: 'danger',
  [PODC_DIGITAL_STATUS.PARTIALLY_REVIEWED]: 'success',
  [PODC_DIGITAL_STATUS.PASS_REVIEW]: 'success',
};

export const PANTHERA_PODC_DELIVERY_ADDRESS = [
  'Pitstop Bawean Semarang',
  'Pitstop Cibitung',
  'Pitstop Pandaan',
  'Pitstop Tanjung Priok',
  'Cikatomas Office',
  'Plaza Asia Office',
];

export const PODC_DELIVERY_SERVICE = {
  GOSEND: 'GOSEND',
  GRAB_EXPRESS: 'GRAB_EXPRESS',
  INTERNAL_COURIER: 'INTERNAL_COURIER',
  JNE: 'JNE',
  OTHER: 'OTHER',
};

export const PODC_DELIVERY_TRACKING_LOG_STATUSES = {
  LOST: 'LOST',
  SENT: 'SENT',
  UPDATE_TRACKING: 'UPDATE_TRACKING',
  DOCUMENT_UPLOADED: 'DOCUMENT_UPLOADED',
  DOCUMENT_DELIVERY_SENDING: 'DOCUMENT_DELIVERY_SENDING',
  DOCUMENT_DELIVERY_COMPLETED: 'DOCUMENT_DELIVERY_COMPLETED',
};

export const PODC_DOCUMENT_REPLACERS = {
  BAP: 'BAP',
  REPRINTED_POD: 'REPRINTED_POD',
};

export const PODC_SENDERS = {
  DELIVERY_SERVICE: 'DELIVERY_SERVICE',
  DRIVER: 'DRIVER',
  INTERNAL_COURIER: 'INTERNAL_COURIER',
  TRANSPORTER: 'TRANSPORTER',
};

export const DELIVERY_SERVICES = {
  JNE: 'JNE',
  OTHER_DELIVERY_SERVICE: 'OTHER_DELIVERY_SERVICE',
};

export const PRICING_SCALING_TYPES = {
  MINIMUM_QUANTITY: 'MINIMUM_QUANTITY',
  GOODS_CAPACITY: 'GOODS_CAPACITY',
};

export const paymentSchemeLabel = {
  '100% di Muka': 'Full_Payment, 100% Upfront (Manual)',
  'DP Rp 200,000 (pelunasan di muat)': 'Split_Payment (200k upfront via Manual)',
  '100% di Bongkar': 'Destination_Payment, 100% After Unloading (Manual)',
  '100% di Muat': 'Origin_Payment, 100% After Loading (Manual)',
  'DP Rp 200,000 (pelunasan di bongkar)': 'Split_Destination (200k upfront via Manual)',
  '50% di Muat - 50% di Bongkar': '50:50_Payment (Origin - Destination)',
  invoice: 'Pembayaran Invoice',
};

export const USER_SALES_ROLE = {
  SME_TELESALES: 'SME_TELESALES',
  SME_SALES_EXECUTIVE: 'SME_SALES_EXECUTIVE',
  SME_TEAM_LEADER: 'SME_TEAM_LEADER',
  SME_SALES_ADMIN: 'SME_SALES_ADMIN',
  SME_SALES_LEAD: 'SME_SALES_LEAD',
};

export const USER_SALES_ROLE_OPTIONS = [
  { label: 'All roles', value: '' },
  { label: 'Telesales', value: USER_SALES_ROLE.SME_TELESALES },
  { label: 'Sales Executive', value: USER_SALES_ROLE.SME_SALES_EXECUTIVE },
  { label: 'Sales Lead', value: USER_SALES_ROLE.SME_SALES_LEAD },
  { label: 'Team Leader', value: USER_SALES_ROLE.SME_TEAM_LEADER },
];

export const USER_ACCESS_ROLE = {
  FULL_ACCESS: 'FULL_ACCESS',
  KARGO_SHIPPER_SALES: 'KARGO_SHIPPER_SALES',
};

export const USER_ACCESS_ROLE_OPTIONS = [
  { label: 'All roles', value: [USER_ACCESS_ROLE.FULL_ACCESS, USER_ACCESS_ROLE.KARGO_SHIPPER_SALES] },
  { label: 'Admin Access', value: USER_ACCESS_ROLE.FULL_ACCESS },
  { label: 'Kargo Team', value: USER_ACCESS_ROLE.KARGO_SHIPPER_SALES },
];

export const USER_STATUS_OPTIONS = [
  { label: 'Active', value: USER_STATUSES.ACTIVE },
  { label: 'Inactive', value: USER_STATUSES.INACTIVE },
  { label: 'Created', value: USER_STATUSES.REGISTERED },
];

export const VENDOR_ASSIGNMENT_TYPE = {
  BROADCAST_CONTRACTED: 'BROADCAST_CONTRACTED',
  PRIVATE_BIDDING: 'PRIVATE_BIDDING',
  SINGLE_CONTRACTED: 'SINGLE_CONTRACTED',
};

// CUSTOMIZABLE INVOICE MODULE (TTMS)
export const CUSTOM_INVOICE_FEE_TYPES = {
  TRUCKING_FEE: 'TRUCKING_FEE',
  OVERNIGHT_FEE: 'OVERNIGHT_FEE',
  LOADING_FEE: 'LOADING_FEE',
  UNLOADING_FEE: 'UNLOADING_FEE',
  OTHER_FEE: 'OTHER_FEE',
  TICKET_FEE: 'TICKET_FEE',
};

export const CUSTOM_INVOICE_FEE_STATUSES = {
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAID: 'PAID',
  NOT_INVOICED: '',
};

export const CUSTOM_INVOICE_FEE_UNITS = {
  PER_CBM: 'PER_CBM',
  PER_ROUTE: 'PER_ROUTE',
  PER_KG: 'PER_KG',
};

export const PRICE_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
};

export const PRICE_STATUS_COLOR = {
  [PRICE_STATUS.ACTIVE]: 'success',
  [PRICE_STATUS.INACTIVE]: 'danger',
};

export const SHIPMENT_EXPENSE_TYPES = {
  TRAVEL_EXPENSE: 'TRAVEL_EXPENSE',
  LOADING_EXPENSE: 'LOADING_EXPENSE',
  UNLOADING_EXPENSE: 'UNLOADING_EXPENSE',
  OVERNIGHT_EXPENSE: 'OVERNIGHT_EXPENSE',
  DRIVER_SALARY_EXPENSE: 'DRIVER_SALARY_EXPENSE',
  OTHER_EXPENSE: 'OTHER_EXPENSE',
};

export const SHIPPER_PLATFROM_HOST = {
  DEV: 'https://shipper-dev.kargo.tech',
  PRD: 'https://shipper.kargo.tech',
  STG: 'https://shipper-qa.kargo.tech',
  INTEGRATION: 'https://shipper-integration.kargo.tech',
}[window.ENVIRONMENT || 'INTEGRATION'];

export const SHIPMENT_POINT_TYPE = {
  PICKUP: 'PICKUP',
  DROPOFF: 'DROPOFF',
};

export const OVERNIGHT_FEE_PRICING_TYPE = {
  FLAT: 'FLAT',
  PROGRESSIVE: 'PROGRESSIVE',
};

export const INVOICE_TAX_CONFIGURATION = {
  ALL_FEE: 'ALL_FEE',
  ONLY_SHIPMENT_FEE: 'ONLY_SHIPMENT_FEE',
  EXCEPT_SHIPMENT_FEE: 'EXCEPT_SHIPMENT_FEE',
  CUSTOM: 'CUSTOM',
};

export const ALLOCATION_TYPE = {
  CONTRACT: 'CONTRACT',
  ON_CALL: 'ON_CALL',
};

export const FILE_SIZES = {
  ORIGINAL: 'ORIGINAL',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
};

export const INCENTIVE_BUSINESS_LINE = {
  SME_REFERRAL: 'SME_REFERRAL',
  SME_MID_MILE: 'SME_MID_MILE',
  ENTERPRISE_REFERRAL: 'ENTERPRISE_REFERRAL',
  ENTERPRISE_MID_MILE: 'ENTERPRISE_MID_MILE',
};

export const INCENTIVE_LEDGER_AMOUNT_TYPE = {
  CREDIT: 'CREDIT',
  DEBIT: 'DEBIT',
  DISBURSEMENT: 'DISBURSEMENT',
};

export const INCENTIVE_LEDGER_STATUS = {
  UPLOADED: 'UPLOADED',
  APPROVED: 'APPROVED',
  AP_REJECTED: 'AP_REJECTED',
  REQUESTED: 'REQUESTED',
  FINANCE_REJECTED: 'FINANCE_REJECTED',
  DISBURSED: 'DISBURSED',
};

export const INCENTIVE_LEDGER_STATUS_COLOR = {
  UPLOADED: 'warning',
  APPROVED: 'primary',
  AP_REJECTED: 'danger',
  REQUESTED: 'primary',
  FINANCE_REJECTED: 'danger',
  DISBURSED: 'success',
};

export const INCENTIVE_BUSINESS_TIMESTAMP_TYPE = {
  POL_UPLOAD: 'POL_UPLOAD',
  POD_UPLOAD: 'POD_UPLOAD',
  // INVOICE_UPLOAD: 'INVOICE_UPLOAD',
  // SHIPMENT_COMPLETE: 'SHIPMENT_COMPLETE',
  // OTHER: 'OTHER'
};

export const PAYMENT_REQUEST_LINE_ITEM_TYPES = {
  SHIPMENT: 'SHIPMENT',
  INCENTIVE: 'INCENTIVE',
};

export const PAYMENT_REQUEST_AMOUNT_SOURCE = {
  FEE_REQUEST: 'FEE_REQUEST',
  INCENTIVE: 'INCENTIVE',
};

export const CUSTOM_TAX_SETTINGS_FLAG = 'CUSTOM_TAX_SETTINGS';

export const SETTINGS_FLAG = {
  CUSTOM_TAX_SETTINGS: 'CUSTOM_TAX_SETTINGS',
  TRANSPORTER_INVOICE_AMOUNT_RESTRICTION: 'TRANSPORTER_INVOICE_AMOUNT_RESTRICTION',
};

export const PROVINCE_DISPLAYED_LABEL = {
  'ID-JK': 'DKI Jakarta',
  'ID-NB': 'NTB',
  'ID-BA': 'Bali',
  'ID-BT': 'Banten',
  'ID-JT': 'Jawa Tengah',
  'ID-JB': 'Jawa Barat',
  'ID-NT': 'NTT',
  'ID-JI': 'Jawa Timur',
  'ID-YO': 'DI Yogyakarta',
  'ID-KT': 'Kalimantan Tengah',
  'ID-KB': 'Kalimantan Barat',
  'ID-KU': 'Kalimantan Utara',
  'ID-KI': 'Kalimantan Timur',
  'ID-KS': 'Kalimantan Selatan',
  'ID-MU': 'Maluku Utara',
  'ID-MA': 'Maluku',
  'ID-PB': 'Papua Barat',
  'ID-PA': 'Papua',
  'ID-BE': 'Bengkulu',
  'ID-JA': 'Jambi',
  'ID-KR': 'Kepulauan Riau',
  'ID-BB': 'Bangka Belitung',
  'ID-SS': 'Sumatera Selatan',
  'ID-AC': 'Aceh',
  'ID-SB': 'Sumatera Barat',
  'ID-SU': 'Sumatera Utara',
  'ID-RI': 'Riau',
  'ID-LA': 'Lampung',
  'ID-SR': 'Sulawesi Barat',
  'ID-SG': 'Sulawesi Tenggara',
  'ID-SA': 'Sulawesi Utara',
  'ID-GO': 'Gorontalo',
  'ID-ST': 'Sulawesi Tengah',
  'ID-SN': 'Sulawesi Selatan',
};

export const BULK_ASSIGN_ERROR = {
  NETWORK: 'network',
  MAX_DO_EXCEEDED: 'max do exceeded',
};

export const LOAD_VALUE_INSURANCE_FEE = 0.135;

export const LOCATION_TYPES = {
  WAREHOUSE: 'Warehouse',
  PORT: 'Port',
};

export const NO_SALES_ADMIN_ROLE = Object.values(USER_SALES_ROLE).filter(el => el !== USER_SALES_ROLE.SME_SALES_ADMIN);

export const SHIPPING_INSTRUCTION_STATUSES = {
  CONFIRMED: 'CONFIRMED',
  EXIST: 'EXIST',
  NOT_EXIST: 'NOT_EXIST',
};

export const SHIPPER_ENTITY_TYPE = {
  SHIPPER_COMPANY: 'SHIPPER_COMPANY',
  SHIPPER_INVOICING_GROUP: 'SHIPPER_INVOICING_GROUP',
};

export const INVOICE_DOCUMENT_TYPES = {
  OCEAN_FREIGHT: 'OCEAN_FREIGHT',
};

export const SUPPORTING_DOCUMENT_TYPES = {
  PROOF_OF_ROUTES_CHANGES: 'PROOF_OF_ROUTES_CHANGES',
};

export const INVOICE_ADD_DOCUMENT_TYPES = {
  THC_L: 'THC-L',
  THC_D: 'THC-D',
  LSS: 'LSS',
  OTHER_FEE: 'OTHER_FEE',
};

export const WarningSI = {
  EMPTY: 'empty',
  NOA: 'noa',
  RESTRICT_EDITED: 'restrict_edit',
  RESTRICT_CREATED: 'restrict_created',
  EMPTY_DOORING_AGENT: 'empty_dooring_agent',
};

export const WarningSIEmptyType = {
  NOA: 'noa',
  OTHER: 'other',
};

export const DISBURSEMENT_STATUS_CODE = {
  INSUFFICIENT_BALANCE: 'INSUFFICIENT_BALANCE',
  UNKNOWN_BANK_NETWORK_ERROR: 'UNKNOWN_BANK_NETWORK_ERROR',
  TEMPORARY_BANK_NETWORK_ERROR: 'TEMPORARY_BANK_NETWORK_ERROR',
  SWITCHING_NETWORK_ERROR: 'SWITCHING_NETWORK_ERROR',
  INVALID_DESTINATION: 'INVALID_DESTINATION',
  REJECTED_BY_BANK: 'REJECTED_BY_BANK',
  TRANSFER_ERROR: 'TRANSFER_ERROR',
  TEMPORARY_TRANSFER_ERROR: 'TEMPORARY_TRANSFER_ERROR',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
};

export const CHARGED_REASON = {
  LATE_BILL_OF_LADING: 'LATE_BILL_OF_LADING',
  DOORING_AGENT_PROBLEM: 'DOORING_AGENT_PROBLEM',
  BAD_WEATHER: 'BAD_WEATHER',
  LONG_QUEUE_AT_WAREHOUSE: 'LONG_QUEUE_AT_WAREHOUSE',
  FULL_AT_DESTINATION_WAREHOUSE: 'FULL_AT_DESTINATION_WAREHOUSE',
};

export const PR_TAX_CONFIGURATION = {
  SHIPMENT_FEES: 'SHIPMENT_FEES',
  SHIPMENT_FEES_AND_OVERNIGHT_FEES: 'SHIPMENT_FEES_AND_OVERNIGHT_FEES',
};

export const PR_NEW_TAX_CONFIGURATION = {
  SHIPMENT_FEES: 'SHIPMENT_FEES',
  SHIPMENT_FEES_OVERNIGHT_FEES_STORAGE_DEMURRAGE_FEES: 'SHIPMENT_FEES_OVERNIGHT_FEES_STORAGE_DEMURRAGE_FEES',
};

export const SHIPPING_LINE_TAB = {
  TO_BE_PROCESSED: 'TO_BE_PROCESSED',
  VESSEL_BOOKED: 'VESSEL_BOOKED',
  SHIPMENT_HISTORY: 'SHIPMENT_HISTORY',
};
export const CANCEL_DO_REASON = {
  CONTAINER_NOT_AVAILABLE: 'CONTAINER_NOT_AVAILABLE',
  CANCELLED_BY_SHIPPER: 'CANCELLED_BY_SHIPPER',
  OTHER_REASON: 'OTHER_REASON',
};

export const VEHICLE_LIST = {
  TRUCK: 'TRUCK',
  VESSEL: 'VESSEL',
};

export const PROJECT_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DRAFT: 'DRAFT',
  DRAFT_1: 'DRAFT_1',
  DRAFT_2: 'DRAFT_2',
  DRAFT_3: 'DRAFT_3',
  DRAFT_4: 'DRAFT_4',
  DRAFT_5: 'DRAFT_5',
  DRAFT_6: 'DRAFT_6',
};

export const TITLE_TRANSLATION = {
  [SHIPPER_FORM_TYPES.CREATE]: 'common:new_shipment',
  [SHIPPER_FORM_TYPES.CONFIRM_NFS]: 'shipment_need_confirmation:confirm_shipment',
  [SHIPPER_FORM_TYPES.EDIT]: 'common:edit_shipment_details',
};

export const TEST_ID_TRUCK_DOC = {
  kirDocuments: 'tglActiveKIR',
  lembarPajakDocuments: 'tglActiveaLembarPajak',
  simDocuments: 'tglActiveSIM',
  stnkDocuments: 'tglActiveSTNK',
  puspakomDocuments: 'tglActivePUSPAKOM',
  gdlDocuments: 'tglActiveGDL',
  taxRoadDocuments: 'tglActiveTaxRoad',
  vehicleRegistrationDocuments: 'tglActiveVehicleRegistration',
};

export const TEST_ID_LOCATION = {
  [LOCATION_FEE_TYPE.LOADING]: { title: 'lblLoadingFee', info: 'lblLoadingInfo' },
  [LOCATION_FEE_TYPE.UNLOADING]: { title: 'lblUnloadingFee', info: 'lblUnloadingInfo' },
  [LOCATION_FEE_TYPE.OTHER]: { title: 'lblOtherFee', info: null },
};

export const ALL_DOCUMENT_TYPES_SPL = {
  [INVOICE_DOCUMENT_TYPES.OCEAN_FREIGHT]: INVOICE_DOCUMENT_TYPES.OCEAN_FREIGHT,
  [INVOICE_ADD_DOCUMENT_TYPES.LSS]: INVOICE_ADD_DOCUMENT_TYPES.LSS,
  [INVOICE_ADD_DOCUMENT_TYPES.THC_L]: INVOICE_ADD_DOCUMENT_TYPES.THC_L,
  [INVOICE_ADD_DOCUMENT_TYPES.THC_D]: INVOICE_ADD_DOCUMENT_TYPES.THC_D,
  [INVOICE_ADD_DOCUMENT_TYPES.OTHER_FEE]: INVOICE_ADD_DOCUMENT_TYPES.OTHER_FEE,
  DTD_BILL_OF_LADING: 'DTD_BILL_OF_LADING',
};

export const GENERIC_ACTIVITY_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const TRUCK_REQUIREMENT_VALUE_TYPE = {
  BOOLEAN: 'BOOLEAN',
  INTEGER_MAX: 'INTEGER_MAX',
  INTEGER_MIN: 'INTEGER_MIN',
};

export const TRUCK_REQUIREMENT_TAGS_PRIORITY_LEVELS = {
  MANDATORY: 0,
  MANDATORY_WITH_FOLLOW_UP: 1,
  OPTIONAL: 2,
};

export const DATE_FORMAT = {
  DD_MMMM_YYYY: 'DD MMMM YYYY',
  DD_MMM_YYYY: 'DD MMM YYYY',
  DD_MMM_YYYY_WITH_TIME: 'DD MMM YYYY, HH:mm',
};

export const FM_SHIPMENT_STATUSES = {
  UPCOMING: 'UPCOMING',
  PLANNED: 'PLANNED',
  QUEUED: 'QUEUED',
  QUEUED_AND_BTMS_PLANNED: 'QUEUED_AND_BTMS_PLANNED',
  ASSIGNED: 'ASSIGNED',
  ASSIGNING: 'ASSIGNING',
  ONGOING_TO_PICKUP: 'ONGOING_TO_PICKUP',
  ARRIVE_AT_PICKUP: 'ARRIVE_AT_PICKUP',
  START_LOADING: 'START_LOADING',
  FINISH_LOADING: 'FINISH_LOADING',
  ONGOING_TO_DESTINATION: 'ONGOING_TO_DESTINATION',
  ON_SHIPMENT: 'ON_SHIPMENT',
  ARRIVE_AT_DESTINATION: 'ARRIVE_AT_DESTINATION',
  START_UNLOADING: 'START_UNLOADING',
  FINISH_UNLOADING: 'FINISH_UNLOADING',
  DOCUMENT_UPLOADED: 'DOCUMENT_UPLOADED',
  SHIPMENT_COMPLETE: 'SHIPMENT_COMPLETE',
  CANCELLED_BY_SHIPPER: 'CANCELLED_BY_SHIPPER',
  CANCELLED_BY_TRANSPORTER: 'CANCELLED_BY_TRANSPORTER',
  CANCELLED: 'CANCELLED',
  UNFULFILLED: 'UNFULFILLED',
  REASSIGNED_BY_SHIPPER: 'REASSIGNED_BY_SHIPPER',
  REJECTED_BY_TRANSPORTER: 'REJECTED_BY_TRANSPORTER',
  CONFIRMATION_EXPIRED: 'CONFIRMATION_EXPIRED',
  WAITING_FOR_CONFIRMATION: 'WAITING_FOR_CONFIRMATION',
  WAITING_FOR_SHIPPER_CONFIRMATION: 'WAITING_FOR_SHIPPER_CONFIRMATION',
  JOB_BIDDING: 'JOB_BIDDING',
  JOB_UNASSIGNED: 'JOB_UNASSIGNED',
  LATE_TO_ORIGIN: 'LATE_TO_ORIGIN',
  LATE_TO_DESTINATION: 'LATE_TO_DESTINATION',
  JOB_TRANSPORTER_CONFIRMED: 'JOB_TRANSPORTER_CONFIRMED',
  WAITING_FOR_JOB_CONFIRMATION: 'WAITING_FOR_JOB_CONFIRMATION',
  BIDDING: 'BIDDING',
  REASSIGNED_BY_BROKERAGE: 'REASSIGNED_BY_BROKERAGE',
  CANCELLED_BY_INTERNAL: 'CANCELLED_BY_INTERNAL',
  UNASSIGNED: 'UNASSIGNED',
  POL_UPLOADED: 'POL_UPLOADED',
  JOB_WAITING_FOR_SHIPPER_CONFIRMATION: 'JOB_WAITING_FOR_SHIPPER_CONFIRMATION',
  GATE_IN_DEPO: 'GATE_IN_DEPO',
  GATE_IN_CY: 'GATE_IN_CY',
  GATE_IN_STORE: 'GATE_IN_STORE',
  GATE_OUT_DEPO: 'GATE_OUT_DEPO',
  GATE_OUT_CY: 'GATE_OUT_CY',
  GATE_OUT_STORE: 'GATE_OUT_STORE',
};

export const MINIMUM_SEARCH_KEYWORD_LENGTH = 3;

export const REDIRECT_QUERY_PARAMS = {
  DTD_CONFIRMATION_FORM: 'DTD_CONFIRMATION_FORM',
};

export const IMAGE_PLACEHOLDER_FALLBACK_URL = '/assets/images/file-placeholder.svg';

export const DISBURSEMENT_METHOD = {
  KARGO: 'KARGO',
  KARGO_MODALKU: 'KARGO_MODALKU',
};

export const DEBOUNCE_TIMEOUT_MILLISECONDS = 1000;

export const CURRENCY_LABELS = {
  RUPIAH: 'Rupiah',
  RINGGIT: 'Ringgit',
};
